import React, { useState, useEffect, useRef } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

//Redux
import { useDispatch, useSelector } from "react-redux"; //useDispatch

//Components
import Layout from "../../../../Layout/Layout";
import Constants from "../../../../common/Constants";
import CommonItemLevelDatatableModal from "../../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";
import {
  addASNDetailNumber,
  addGRNDetailNumber,
  addInvoiceDetailNumber,
  addPoSelectedDetails,
  addSelectedItemList,
  addSesDetailNumber,
  addSubTransactionActivity,
  getPurchaseOrder,
  getSchedulingAgreement,
  getServicePO,
} from "../../../../redux/feature/supplier_app/purchasingSlice";
import { InputField } from "../../../../Components/formElements/InputField";
// import POCollabrationCommonFillter from "../../../Components/Filter/POCollabration/POCollabrationCommonFillter";

//Serivce
import ItemService from "../../../../services/supplier_appCommonService";
import ApproveRemarksModal from "../../../../Components/Modal/supplier_app/ApproveRemarksModal";
import SuccessModal from "../../../../Components/Modal/supplier_app/SuccessModal";
import { capitalize, currencyFormat } from "../../../../common/Validations";

const PurchaseOrderDetailedPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const divRef = useRef(null);

  let getConstantVar = useSelector((state) => state?.common?.data); // User getConstantVar from Store
  let userInfo = useSelector((state) => state?.user?.data); // User Details from Store
  let purchase_order_data = useSelector(
    (state) => state?.purchasing?.purchase_order?.data
  ); // purchase_order_data Details from Store
  let poNumber = useSelector(
    (state) => state?.purchasing?.purchase_order?.poNumber
  ); // purchase_order from Store
  let workflow = useSelector(
    (state) => state?.purchasing?.purchase_order?.workflowPO
  ); // workflowPO from Store

  let config = useSelector(
    (state) => state?.common?.data?.purchaseOrder?.poDetailsPage
  ); // config Details from Store

  /* success modal */
  const successModalData = {
    title: "Message",
    successMsg: "",
  };
  const [getShowSuccessModal, setShowSuccessModal] = useState(false);
  const [getsucceModalData, setsucceModalData] = useState(successModalData);
  const [getNextNavigate, setNextNavigate] = useState("");
  const onSuccessmodel = () => {
    setShowSuccessModal(true);
  };
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [getHeaderData, setHeaderData] = useState(false);
  // const [totalRows, setTotalRows] = useState(10);
  // const [perPage, setPerPage] = useState(10);
  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);

  const [modalShow, setModalShow] = useState(false);

  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");

  const [getViewHeaderText, setViewHeaderText] = useState(false);

  const [disabled, setDisabled] = useState(false);
  // eslint-disable-next-line
  const [getSelectedData, setSelectedData] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [getAction, setAction] = useState("");

  const [getGrnHistoricalData, setGrnHistoricalData] = useState([]);
  const [getAsnHistoricalData, setAsnHistoricalData] = useState([]);
  const [getInvoiceHistoricalData, setInvoiceHistoricalData] = useState([]);
  const [getSesHistoricalData, setSesHistoricalData] = useState([]);

  //Service Items
  let serviceColInfo = [
    {
      name: "Service Line Nr",
      cell: (row) => <span>{row.serviceLineNr} </span>,
      width: "180px",
    },
    {
      name: "Service Number",
      cell: (row) => <span>{row.serviceNr} </span>,
      width: "180px",
    },
    {
      name: "Service Desc",
      cell: (row) => <span>{row.serviceDescription} </span>,
      width: "180px",
    },
    {
      name: "UOM",
      cell: (row) => <span>{row.orderUnitOfMeasure} </span>,
      width: "180px",
    },
    {
      name: "Service Quantity",
      cell: (row) => <span>{row.serviceQuantity} </span>,
      width: "180px",
    },
    {
      name: "Unit Value",
      cell: (row) => <span>{row.unitValue} </span>,
      width: "180px",
    },
    {
      name: "Gross Value",
      cell: (row) => <span>{row.grossValue} </span>,
      width: "180px",
    },
    {
      name: "Delivery Date",
      cell: (row) => <span>{row.deliveryDate} </span>,
      width: "180px",
    },
  ];
  //ScheduleItems
  let scheduleColInfo = [
    {
      name: "Schedule LineNr",
      cell: (row) => <span>{row.scheduleLineNr} </span>,
      width: "180px",
    },
    {
      name: "Day/Week/Month",
      cell: (row) => <span>{row.schedulePeriod} </span>,
      width: "180px",
    },
    {
      name: "Schedule From",
      cell: (row) => <span>{row.scheduleFromDate} </span>,
      width: "180px",
    },
    {
      name: "Schedule To",
      cell: (row) => <span>{row.scheduleToDate} </span>,
      width: "180px",
    },
    {
      name: "Schedule Quantity",
      cell: (row) => <span>{row.scheduleQuantity} </span>,
      width: "180px",
    },
    {
      name: " Reservation No",
      cell: (row) => <span>{row.reservationNr} </span>,
      width: "180px",
    },
    {
      name: "Delivery Date",
      cell: (row) => <span>{row.deliveryDate} </span>,
      width: "180px",
    },
  ];
  const [getItemNr, setItemNr] = useState("");
  const [getServiceData, setServiceData] = useState([]);
  const [getServiceTableStatus, setServiceTableStatus] = useState(false);

  useEffect(() => {
    if (divRef.current) {
      try {
        // eslint-disable-next-line no-unused-vars
        const styles = window.getComputedStyle(divRef.current);
      } catch (error) {
        console.error("Failed to get computed style:", error);
      }
    }
  }, [getServiceTableStatus]);

  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);
  // OnClick on lineItem  Data
  const lineItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(lineItemColInfo);
    setModalTitle("Item Text Details");
    setItemLevelModalShow(true);
  };
  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };

  // OnClick on Header Text  Data
  const headerTextShowModal = (headerTextData) => {
    setItemLevelData(headerTextData);
    setItemLevelColumns(lineItemColInfo);
    setModalTitle("Header Text Details");
    setViewHeaderText(true);
  };
  const headerTextHideModal = () => {
    setViewHeaderText(false);
  };

  //--------------- Start Items Pop for history tables  -----------------------------------------------------

  // ASN Items
  let ASNLineItemColInfo = [
    {
      name: "Item Number",
      cell: (row) => <span>{row.purchasingDocumentItemNr} </span>,
      width: "180px",
    },
    {
      name: "Material",
      cell: (row) => <span>{row.materialCode} </span>,
      width: "180px",
    },
    {
      name: "Material Desc",
      cell: (row) => <span>{row.materialDescription} </span>,
      width: "220px",
    },
    {
      name: "Material Group",
      cell: (row) => <span>{row.materialClass2} </span>,
      width: "220px",
    },
    {
      name: "Quantity",
      cell: (row) => <span>{row.purchaseQuantity} </span>,
      width: "220px",
    },
    {
      name: "Price Unit",
      cell: (row) => <span>{row.orderPriceUnit} </span>,
      width: "220px",
    },
  ];

  const [getASNitemLevelModalShow, setASNitemLevelModalShow] = useState(false);
  // OnClick on lineItem  Data
  const ASNLineItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(ASNLineItemColInfo);
    setModalTitle("ASN Line Item Details");
    setASNitemLevelModalShow(true);
  };
  const ASNItemLevelHideModal = () => {
    setASNitemLevelModalShow(false);
  };

  // GRN Items
  let GRNLineItemColInfo = [
    {
      name: "Item Number",
      cell: (row) => <span>{row.purchasingDocumentItemNr} </span>,
      width: "180px",
    },
    {
      name: "Material",
      cell: (row) => <span>{row.materialCode} </span>,
      width: "180px",
    },
    {
      name: "Material Desc",
      cell: (row) => <span>{row.materialDescription} </span>,
      width: "220px",
    },
    {
      name: "Material Group",
      cell: (row) => <span>{row.materialClass2} </span>,
      width: "220px",
    },
    {
      name: "Quantity",
      cell: (row) => <span>{row.purchaseQuantity} </span>,
      width: "220px",
    },
    {
      name: "Price Unit",
      cell: (row) => <span>{row.orderPriceUnit} </span>,
      width: "220px",
    },
  ];

  const [getGRNitemLevelModalShow, setGRNitemLevelModalShow] = useState(false);
  // OnClick on lineItem  Data
  const GRNLineItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(GRNLineItemColInfo);
    setModalTitle("GRN Line Item Details");
    setGRNitemLevelModalShow(true);
  };
  const GRNLItemLevelHideModal = () => {
    setGRNitemLevelModalShow(false);
  };

  // Invoice Items
  let InvoiceLineItemColInfo = [
    {
      name: "Item Number",
      cell: (row) => <span>{row.invoiceItemNr} </span>,
      width: "180px",
    },
    {
      name: "Material",
      cell: (row) => <span>{row.materialCode} </span>,
      width: "180px",
    },
    {
      name: "Material Desc",
      cell: (row) => <span>{row.materialDescription} </span>,
      width: "220px",
    },
    {
      name: "UOM",
      cell: (row) => <span>{row.orderUnitOfMeasure} </span>,
      width: "220px",
    },
    {
      name: "Quantity",
      cell: (row) => <span>{row.invoiceQuantity} </span>,
      width: "220px",
    },
    {
      name: "Price Unit",
      cell: (row) => <span>{row.orderPriceUnit} </span>,
      width: "220px",
    },
    {
      name: "Total Price",
      cell: (row) => <span>{row.totalPrice} </span>,
      width: "220px",
    },
    {
      name: "Tax %",
      cell: (row) => <span>{row.taxCode} </span>,
      width: "220px",
    },
    {
      name: "Total Tax",
      cell: (row) => <span>{row.totalTax} </span>,
      width: "220px",
    },
  ];
  const [getInvoiceitemLevelModalShow, setInvoiceitemLevelModalShow] =
    useState(false);
  // OnClick on lineItem  Data
  const InvoiceLineItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(InvoiceLineItemColInfo);
    setModalTitle("Invoice Line Item Details");
    setInvoiceitemLevelModalShow(true);
  };
  const InvoiceItemLevelHideModal = () => {
    setInvoiceitemLevelModalShow(false);
  };

  // SES Items
  let SESLineItemColInfo = [
    {
      name: "Item Number",
      cell: (row) => <span>{row.purchasingDocumentItemNr} </span>,
      width: "180px",
    },
    {
      name: "Material",
      cell: (row) => <span>{row.materialCode} </span>,
      width: "180px",
    },
    {
      name: "Material Desc",
      cell: (row) => <span>{row.materialDescription} </span>,
      width: "220px",
    },
    {
      name: "Material Group",
      cell: (row) => <span>{row.materialClass2} </span>,
      width: "220px",
    },
    {
      name: "Quantity",
      cell: (row) => <span>{row.purchaseQuantity} </span>,
      width: "220px",
    },
    {
      name: "Price Unit",
      cell: (row) => <span>{row.orderPriceUnit} </span>,
      width: "220px",
    },
  ];

  const [getSESitemLevelModalShow, setSESitemLevelModalShow] = useState(false);
  // OnClick on lineItem  Data
  const SESLineItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(SESLineItemColInfo);
    setModalTitle("SES Line Item Details");
    setSESitemLevelModalShow(true);
  };
  const SESLItemLevelHideModal = () => {
    setSESitemLevelModalShow(false);
  };

  //---------------  END Items Pop for history tables   -----------------------------------------------------

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };

  const hideModal = () => {
    setModalShow(false);
  };

  const loadInitialPageData = async () => {
    setLoading(true);
    if (poNumber) {
      let obj = {
        documentNumber: poNumber,
      };
      const toastId = toast.loading("Loading...", {
        position: "top-right",
      });
      await ItemService.getPurchaseOrderDetail(obj).then((item) => {
        // console.log(">> res!", item);
        if (item?.status === 200) {
          setHeaderData(item.data);
          setData(item?.data?.items);
          dispatch(addPoSelectedDetails(item?.data));
        } else {
          toast.error(item?.message || "Please try again !!", {
            duration: 4000,
            position: "top-right",
          });
        }
        toast.dismiss(toastId);
      });

      setLoading(false);
    } else {
      window.history.back();
    }
  };

  useEffect(() => {
    loadInitialPageData(); // fetch page 1 of users
    loadGrnHistoricalData(); // Load GRN History Data
    loadAsnHistoricalData(); // Load ASN History Data
    loadInvoiceHistoricalData(); // Load Invoice History Data
    loadSesHistoricalData(); // Load SES History Data
    dispatch(addSelectedItemList([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poNumber, purchase_order_data]);

  const detailedPageGRN = (grnNumber) => {
    dispatch(addGRNDetailNumber(grnNumber));
    navigate(Constants.POCOLLABRATION_GRN_DETAILS);
  };

  const detailedPageASN = (ansNumber) => {
    dispatch(addASNDetailNumber(ansNumber));
    navigate(Constants.POCOLLABRATION_ASN_DETAIL_PAGE);
  };

  const detailedPageInvoice = (ansNumber) => {
    dispatch(addInvoiceDetailNumber(ansNumber));
    navigate(Constants.POCOLLABRATION_INVOICE_DETIAILS);
  };

  const detailedPageSES = (sesNumber) => {
    dispatch(addSesDetailNumber(sesNumber));
    navigate(Constants.POCOLLABRATION_SERVICE_ENTRY_DETAILS);
  };

  // PO columns Details
  const columns = [
    {
      name: "PO Number",
      selector: (row) => poNumber,
      sortable: true,
      width: "150px",
    },
    {
      name: "PO Item Nr",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "150px",
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          {row.statusItem === "New" ? (
            <span className="bg-table-status bg-table-new-status">
              {row.statusItem}
            </span>
          ) : row.statusItem === "In Release" ? (
            <span className="bg-table-status bg-table-progress-status">
              {row.statusItem}
            </span>
          ) : row.statusItem === "Acknowledged" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.statusItem}
            </span>
          ) : row.statusItem === "Cancelled" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.statusItem}
            </span>
          ) : (
            row.statusItem
          )}
        </>
      ),
      width: "160px",
    },
    {
      name: "Material/Service",
      selector: (row) => row.materialCode,
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.materialCode} </p>
          <p className="m-0 p-0">{row.materialDescription} </p>
        </div>
      ),
    },
    {
      name: "Item Text",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => lineItemShowModal(row.itemTextData)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon">
            {" "}
            {row.itemTextData?.length || 0}{" "}
          </span>
        </>
      ),
      width: "100px",
    },
    {
      name: "Purchase Quantity",
      selector: (row) => row.purchaseQuantity,
      sortable: true,
      width: "180px",
    },
    {
      name: "Oder Unit",
      selector: (row) => row.orderUnitOfMeasure,
      sortable: true,
      width: "120px",
    },
    {
      name: "Unit Price",
      selector: (row) => row.orderPriceUnit,
      sortable: true,
      width: "120px",
    },
    {
      name: "Item Value",
      // selector: (row) => row.netValue,
      cell: (row) => (
        <span className="numberFormate">
          {currencyFormat(Number(row.netValue).toFixed(2))}
        </span>
      ),

      sortable: true,
      width: "180px",
    },
    {
      name: "Currency",
      selector: (row) => row.currencyCode,
      sortable: true,
      width: "120px",
    },
    {
      name: "Delivery Date",
      selector: (row) => row.deliveryDate,
      sortable: true,
      width: "140px",
    },
    {
      name: "Tax Amount",
      selector: (row) => row.taxAmount,
      sortable: true,
      width: "200px",
    },
    // {
    //   name: "Price Unit Qty",
    //   selector: (row) => row.priceUnit,
    //   sortable: true,
    //   width: "200px",
    // },
    {
      name: "Tax Code",
      selector: (row) => row.taxId,
      sortable: true,
      width: "200px",
    },
    {
      name: "Tax Desc",
      selector: (row) => row.taxIdDesc,
      sortable: true,
      width: "200px",
    },
    {
      name: "Free Of Cost",
      selector: (row) => row.freeCost,
      sortable: true,
      width: "200px",
    },
    {
      name: "Purch Req Nr",
      selector: (row) => row.requestNumber,
      sortable: true,
      width: "200px",
    },
    {
      name: "PR Item Nr",
      selector: (row) => row.prItemNr,
      sortable: true,
      width: "200px",
    },
    {
      name: "ExcessTolerance",
      selector: (row) => row.excessTolerance,
      sortable: true,
      width: "200px",
    },
    {
      name: "ShortageTolerance",
      selector: (row) => row.shortageTolerance,
      sortable: true,
      width: "200px",
    },
  ];

  const columnsService = [
    {
      name: "PO Number",
      selector: (row) => poNumber,
      sortable: true,
      width: "150px",
    },
    {
      name: "PO Item Nr",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "150px",
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          {row.statusItem === "New" ? (
            <span className="bg-table-status bg-table-new-status">
              {row.statusItem}
            </span>
          ) : row.statusItem === "In Release" ? (
            <span className="bg-table-status bg-table-progress-status">
              {row.statusItem}
            </span>
          ) : row.statusItem === "Acknowledged" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.statusItem}
            </span>
          ) : row.statusItem === "Cancelled" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.statusItem}
            </span>
          ) : (
            row.statusItem
          )}
        </>
      ),
      width: "160px",
    },
    {
      name: "Material/Service",
      selector: (row) => row.materialCode,
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.materialCode} </p>
          <p className="m-0 p-0">{row.materialDescription} </p>
        </div>
      ),
    },
    {
      name: "Item Text",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => lineItemShowModal(row.itemTextData)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon">
            {" "}
            {row.itemTextData?.length || 0}{" "}
          </span>
        </>
      ),
      width: "100px",
    },
    {
      name: "Service",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() =>
              showServiceItemTable(row.services, row.purchasingDocumentItemNr)
            }
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.services?.length || 0} </span>
        </>
      ),
      width: "180px",
    },
    {
      name: "Purchase Quantity",
      selector: (row) => row.purchaseQuantity,
      sortable: true,
      width: "180px",
    },
    {
      name: "Oder Unit",
      selector: (row) => row.orderUnitOfMeasure,
      sortable: true,
      width: "120px",
    },
    {
      name: "Unit Price",
      selector: (row) => row.orderPriceUnit,
      sortable: true,
      width: "120px",
    },
    {
      name: "Item Value",
      // selector: (row) => row.netValue,
      cell: (row) => (
        <span className="numberFormate">
          {currencyFormat(Number(row.netValue).toFixed(2))}
        </span>
      ),

      sortable: true,
      width: "180px",
    },
    {
      name: "Currency",
      selector: (row) => row.currencyCode,
      sortable: true,
      width: "120px",
    },
    {
      name: "Delivery Date",
      selector: (row) => row.deliveryDate,
      sortable: true,
      width: "140px",
    },
    {
      name: "Tax Amount",
      selector: (row) => row.taxAmount,
      sortable: true,
      width: "200px",
    },
    // {
    //   name: "Price Unit Qty",
    //   selector: (row) => row.priceUnit,
    //   sortable: true,
    //   width: "200px",
    // },
    {
      name: "Tax Code",
      selector: (row) => row.taxId,
      sortable: true,
      width: "200px",
    },
    {
      name: "Tax Desc",
      selector: (row) => row.taxIdDesc,
      sortable: true,
      width: "200px",
    },
    {
      name: "Free Of Cost",
      selector: (row) => row.freeCost,
      sortable: true,
      width: "200px",
    },
    {
      name: "Purch Req Nr",
      selector: (row) => row.requestNumber,
      sortable: true,
      width: "200px",
    },
    {
      name: "PR Item Nr",
      selector: (row) => row.prItemNr,
      sortable: true,
      width: "200px",
    },
    {
      name: "ExcessTolerance",
      selector: (row) => row.excessTolerance,
      sortable: true,
      width: "200px",
    },
    {
      name: "ShortageTolerance",
      selector: (row) => row.shortageTolerance,
      sortable: true,
      width: "200px",
    },
  ];

  const columnsSchedule = [
    {
      name: "PO Number",
      selector: (row) => poNumber,
      sortable: true,
      width: "150px",
    },
    {
      name: "PO Item Nr",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "150px",
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          {row.statusItem === "New" ? (
            <span className="bg-table-status bg-table-new-status">
              {row.statusItem}
            </span>
          ) : row.statusItem === "In Release" ? (
            <span className="bg-table-status bg-table-progress-status">
              {row.statusItem}
            </span>
          ) : row.statusItem === "Acknowledged" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.statusItem}
            </span>
          ) : row.statusItem === "Cancelled" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.statusItem}
            </span>
          ) : (
            row.statusItem
          )}
        </>
      ),
      width: "160px",
    },
    {
      name: "Material/Service",
      selector: (row) => row.materialCode,
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.materialCode} </p>
          <p className="m-0 p-0">{row.materialDescription} </p>
        </div>
      ),
    },
    {
      name: "Item Text",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => lineItemShowModal(row.itemTextData)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon">
            {" "}
            {row.itemTextData?.length || 0}{" "}
          </span>
        </>
      ),
      width: "100px",
    },
    {
      name: "Schedules",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() =>
              showServiceItemTable(row.schedule, row.purchasingDocumentItemNr)
            }
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.schedule?.length || 0} </span>
        </>
      ),
      width: "180px",
    },
    {
      name: "Purchase Quantity",
      selector: (row) => row.purchaseQuantity,
      sortable: true,
      width: "180px",
    },
    {
      name: "Oder Unit",
      selector: (row) => row.orderUnitOfMeasure,
      sortable: true,
      width: "120px",
    },
    {
      name: "Unit Price",
      selector: (row) => row.orderPriceUnit,
      sortable: true,
      width: "120px",
    },
    {
      name: "Item Value",
      // selector: (row) => row.netValue,
      cell: (row) => (
        <span className="numberFormate">
          {currencyFormat(Number(row.netValue).toFixed(2))}
        </span>
      ),

      sortable: true,
      width: "180px",
    },
    {
      name: "Currency",
      selector: (row) => row.currencyCode,
      sortable: true,
      width: "120px",
    },
    {
      name: "Delivery Date",
      selector: (row) => row.deliveryDate,
      sortable: true,
      width: "140px",
    },
    {
      name: "Tax Amount",
      selector: (row) => row.taxAmount,
      sortable: true,
      width: "200px",
    },
    // {
    //   name: "Price Unit Qty",
    //   selector: (row) => row.priceUnit,
    //   sortable: true,
    //   width: "200px",
    // },
    {
      name: "Tax Code",
      selector: (row) => row.taxId,
      sortable: true,
      width: "200px",
    },
    {
      name: "Tax Desc",
      selector: (row) => row.taxIdDesc,
      sortable: true,
      width: "200px",
    },
    {
      name: "Free Of Cost",
      selector: (row) => row.freeCost,
      sortable: true,
      width: "200px",
    },
    {
      name: "Purch Req Nr",
      selector: (row) => row.requestNumber,
      sortable: true,
      width: "200px",
    },
    {
      name: "PR Item Nr",
      selector: (row) => row.prItemNr,
      sortable: true,
      width: "200px",
    },
    {
      name: "ExcessTolerance",
      selector: (row) => row.excessTolerance,
      sortable: true,
      width: "200px",
    },
    {
      name: "ShortageTolerance",
      selector: (row) => row.shortageTolerance,
      sortable: true,
      width: "200px",
    },
  ];

  // PO items columns Details
  const lineItemColInfo = [
    {
      name: "Code",
      cell: (row) => <span>{row.documentId} </span>,
      width: "200px",
    },
    {
      name: "Text",
      cell: (row) => <span>{row.documentText} </span>,
      width: "250px",
    },
  ];

  //GRN Historical Table
  const grnHistoryColInfo = [
    {
      name: "GRN Number",
      selector: (row) => row.grnNr,
      cell: (row) => (
        <button className="btnInfo" onClick={() => detailedPageGRN(row.grnNr)}>
          {row.grnNr}{" "}
        </button>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Collaboration",
      cell: (row) => (
        <>
          <span>
            <i className="bi bi-git iconTable"></i>
          </span>
          <span>
            <i className="bi bi-collection-play iconTable"></i>
          </span>
          <span>
            <i className="bi bi-stack iconTable"></i>
          </span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => row.grnStatusHeader,
      cell: (row) => (
        <>
          {row.grnStatusHeader === "GRN Created" ? (
            <span className="bg-table-status bg-table-new-status">
              {row.grnStatusHeader}
            </span>
          ) : row.grnStatusHeader === "Pending for Approval" ? (
            <span className="bg-table-status bg-table-progress-status">
              {row.grnStatusHeader}
            </span>
          ) : row.grnStatusHeader === "GRN Cancelled" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.grnStatusHeader}
            </span>
          ) : row.grnStatusHeader === "Invoice Cancelled" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.grnStatusHeader}
            </span>
          ) : row.grnStatusHeader === "GRN Rejected" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.grnStatusHeader}
            </span>
          ) : row.grnStatusHeader === "Posted" ? (
            <span className="bg-table-status bg-table-info-status">
              {row.grnStatusHeader}
            </span>
          ) : row.grnStatusHeader === "Invoice Created" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.grnStatusHeader}
            </span>
          ) : (
            row.grnStatusHeader
          )}
        </>
      ),
      sortable: true,
      width: "160px",
    },
    {
      name: "GRN Date",
      selector: (row) => row.shippmentDate || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Line Items",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => GRNLineItemShowModal(row.items)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.items?.length} </span>
        </>
      ),
      width: "120px",
    },
    {
      name: "Vendor",
      selector: (row) => row.vendorCode,
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.vendorCode} </p>
          <p className="m-0 p-0">{row.vendorName} </p>
        </div>
      ),
    },
    {
      name: "SAP IBD ",
      selector: (row) => row.sapInboundDelivery || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "SAP GRN",
      selector: (row) => row.grnNumber || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "Posted On",
      selector: (row) => row.postedOn || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "GRN Value",
      selector: (row) => row.sumOfGrn || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Currency",
      selector: (row) => row.currencyCode || "--",

      width: "150px",
    },
    // {
    //   name: "Purchase Order",
    //   selector: (row) => row.items[0]?.purchasingDocumentNr || "--",
    //   sortable: true,
    //   width: "150px",
    // },
    {
      name: "Delivery Note",
      selector: (row) => row.deliveryNoteNo || "--",
      sortable: true,
      width: "140px",
    },

    {
      name: "Bill of Lading",
      selector: (row) => row.billOfLading || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Cancelled GRN Nr",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "180px",
    },
    {
      name: "Cancelled GRN Date",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "180px",
    },
    {
      name: "Cancelled GRN Remark",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "200px",
    },
  ];

  // ASN Historical Table
  const asnHistoryColInfo = [
    {
      name: "ASN Number",
      selector: (row) => row.asnNumber,
      cell: (row) => (
        <button
          className="btnInfo"
          onClick={() => detailedPageASN(row.asnNumber)}
        >
          {row.asnNumber}{" "}
        </button>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Collaboration",
      cell: (row) => (
        <>
          <span>
            <i className="bi bi-git iconTable"></i>
          </span>
          <span>
            <i className="bi bi-collection-play iconTable"></i>
          </span>
          <span>
            <i className="bi bi-stack iconTable"></i>
          </span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => row.asnStatusHeader,
      cell: (row) => (
        <>
          {row.asnStatusHeader === "In Transit" ? (
            <span className="bg-table-status bg-table-progress-status">
              {row.asnStatusHeader}
            </span>
          ) : row.asnStatusHeader === "ASN Cancelled" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.asnStatusHeader}
            </span>
          ) : row.asnStatusHeader === "Gatepass Created" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.asnStatusHeader}
            </span>
          ) : (
            row.asnStatusHeader
          )}
        </>
      ),
      sortable: true,
      width: "160px",
    },
    {
      name: "ASN Date",
      selector: (row) => row.asnDate || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Line Items",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => ASNLineItemShowModal(row.items)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.items?.length} </span>
        </>
      ),
      width: "120px",
    },
    {
      name: "Vendor",
      selector: (row) => row.vendorCode,
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.vendorCode} </p>
          <p className="m-0 p-0">{row.vendorName} </p>
        </div>
      ),
    },
    {
      name: "SAP IBD ",
      selector: (row) => row.sapInboundDelivery || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "SAP GRN",
      selector: (row) => row.grnNumber || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "Posted On",
      selector: (row) => row.postedOn || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Invoice Number",
      selector: (row) => row.vendorInvoiceNo,
      sortable: true,
      width: "180px",
    },
    {
      name: "Invoice Date",
      selector: (row) => row.vendorInvoiceDate || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Invoice Value",
      selector: (row) => row.vendorTotalInvoiceValue || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Currency",
      selector: (row) => row.currencyCode,
      sortable: true,
      width: "150px",
    },
    {
      name: "Shipping Type",
      cell: (row) => (
        <>
          <span> {capitalize(row.shippingType)} </span>
        </>
      ),
      width: "120px",
    },
    {
      name: "Gatepass Number",
      selector: (row) => row?.gatepassNr || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Gatepass Date",
      selector: (row) => row?.gatepassDate || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Gatepass Time",
      selector: (row) => row?.gatepassTime || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Cancelled ASN Nr",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "180px",
    },
    {
      name: "Cancelled ASN Date",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "180px",
    },
    {
      name: "Cancelled ASN Remark",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "200px",
    },
  ];

  // Invoice Historical Table
  const invoiceDataColumns = [
    {
      name: "Invoice Number",
      selector: (row) => row.invoiceNr,
      cell: (row) => (
        <button
          className="btnInfo"
          onClick={() => detailedPageInvoice(row.invoiceNr)}
        >
          {row.invoiceNr}{" "}
        </button>
      ),
      sortable: true,
      width: "160px",
    },
    {
      name: "Collaboration",
      cell: (row) => (
        <>
          <span>
            <i className="bi bi-git iconTable"></i>
          </span>
          <span>
            <i className="bi bi-collection-play iconTable"></i>
          </span>
          <span>
            <i className="bi bi-stack iconTable"></i>
          </span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => row.invoiceStatusHeader,
      cell: (row) => (
        <>
          {row.invoiceStatusHeader === "Invoice Created" ? (
            <span className="bg-table-status bg-table-new-status">
              {row.invoiceStatusHeader}
            </span>
          ) : row.invoiceStatusHeader === "Pending for Approval" ? (
            <span className="bg-table-status bg-table-progress-status">
              {row.invoiceStatusHeader}
            </span>
          ) : row.invoiceStatusHeader === "SES Cancelled" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.invoiceStatusHeader}
            </span>
          ) : row.invoiceStatusHeader === "Invoice Cancelled" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.invoiceStatusHeader}
            </span>
          ) : row.invoiceStatusHeader === "SES Rejected" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.invoiceStatusHeader}
            </span>
          ) : row.invoiceStatusHeader === "Posted" ? (
            <span className="bg-table-status bg-table-info-status">
              {row.invoiceStatusHeader}
            </span>
          ) : row.invoiceStatusHeader === "Invoice Approved" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.invoiceStatusHeader}
            </span>
          ) : (
            row.invoiceStatusHeader
          )}
        </>
      ),
      sortable: true,
      width: "180px",
    },
    // {
    //   name: "Status",
    //   cell: (row) => (
    //     <>
    //       <span className="bg-table-status bg-table-new-status">
    //         {row.invoiceStatusHeader}
    //       </span>
    //     </>
    //   ),
    //   width: "160px",
    // },
    {
      name: "Invoice Date",
      selector: (row) => row.vendorInvoiceDate,
      sortable: true,
      width: "130px",
    },
    {
      name: "Line Items",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => InvoiceLineItemShowModal(row.items)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.items?.length} </span>
        </>
      ),
      width: "120px",
    },
    {
      name: "Vendor",
      selector: (row) => row.vendorCode,
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.vendorCode} </p>
          <p className="m-0 p-0">{row.vendorName} </p>
        </div>
      ),
    },
    {
      name: "SAP Invoice",
      selector: (row) => row.sapinvoiceNr || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Posted On",
      selector: (row) => row.sapPostedOn || "--",
      sortable: true,
      width: "130px",
    },
    {
      name: "Invoice Value",
      selector: (row) => row.vendorTotalInvoiceValue,
      sortable: true,
      width: "150px",
    },
    {
      name: "Currency",
      selector: (row) => row.currencyCode,
      sortable: true,
      width: "120px",
    },
    {
      name: "Payment Terms",
      selector: (row) => row.paymentTerms,
      sortable: true,
      width: "150px",
    },
    {
      name: "Cancelled INV Nr",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "180px",
    },
    {
      name: "Cancelled INV Date",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "180px",
    },
    {
      name: "Cancelled INV Remark",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "200px",
    },
  ];

  //SES Historical Table
  const sesHistoryColInfo = [
    {
      name: "SES Number",
      selector: (row) => row.documentNumber,
      cell: (row) => (
        <button
          className="btnInfo"
          onClick={() => detailedPageSES(row.documentNumber)}
        >
          {row.documentNumber}{" "}
        </button>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Collaboration",
      cell: (row) => (
        <>
          <span>
            <i className="bi bi-git iconTable"></i>
          </span>
          <span>
            <i className="bi bi-collection-play iconTable"></i>
          </span>
          <span>
            <i className="bi bi-stack iconTable"></i>
          </span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => row.sesStatusHeader,
      cell: (row) => (
        <>
          {row.sesStatusHeader === "SES Created" ? (
            <span className="bg-table-status bg-table-new-status">
              {row.sesStatusHeader}
            </span>
          ) : row.sesStatusHeader === "Pending for Approval" ? (
            <span className="bg-table-status bg-table-progress-status">
              {row.sesStatusHeader}
            </span>
          ) : row.sesStatusHeader === "SES Cancelled" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.sesStatusHeader}
            </span>
          ) : row.sesStatusHeader === "Invoice Cancelled" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.sesStatusHeader}
            </span>
          ) : row.sesStatusHeader === "SES Rejected" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.sesStatusHeader}
            </span>
          ) : row.sesStatusHeader === "Posted" ? (
            <span className="bg-table-status bg-table-info-status">
              {row.sesStatusHeader}
            </span>
          ) : row.sesStatusHeader === "Invoice Created" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.sesStatusHeader}
            </span>
          ) : (
            row.sesStatusHeader
          )}
        </>
      ),
      sortable: true,
      width: "160px",
    },
    {
      name: "SES Date",
      selector: (row) => row.shippmentDate || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Line Items",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => SESLineItemShowModal(row.items)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.items?.length} </span>
        </>
      ),
      width: "120px",
    },
    {
      name: "Vendor",
      selector: (row) => row.vendorCode,
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.vendorCode} </p>
          <p className="m-0 p-0">{row.vendorName} </p>
        </div>
      ),
    },
    {
      name: "SAP IBD ",
      selector: (row) => row.sapInboundDelivery || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "SAP SES",
      selector: (row) => row.sesNumber || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "Posted On",
      selector: (row) => row.postedOn || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "SES Value",
      selector: (row) => row.sumOfSes || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Currency",
      selector: (row) => row.currencyCode || "--",

      width: "150px",
    },
    // {
    //   name: "Purchase Order",
    //   selector: (row) => row.items[0]?.purchasingDocumentNr || "--",
    //   sortable: true,
    //   width: "150px",
    // },
    {
      name: "Delivery Note",
      selector: (row) => row.deliveryNoteNo || "--",
      sortable: true,
      width: "140px",
    },

    {
      name: "Bill of Lading",
      selector: (row) => row.billOfLading || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Cancelled SES Nr",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "180px",
    },
    {
      name: "Cancelled SES Date",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "180px",
    },
    {
      name: "Cancelled SES Remark",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "200px",
    },
  ];

  // Onclick on Table Header Button
  const handleAcknowledgeClick = async () => {
    let obj = {
      _id: getHeaderData?._id,
      purchasingDocumentNr: getHeaderData?.purchasingDocumentNr,
    };

    // if (getSelectedData.length > 0) {
    await ItemService.AcknowledgePO(obj).then((item) => {
      if (item?.status === 200) {
        toast.success(item?.message || "PO Acknowledged successfully", {
          duration: 3000,
          position: "top-right",
        });

        setDisabled(true);
        setToggleCleared(true);
        setSelectedData([]);

        let materialPO = {
          queryName: "getMaterialPO",
          module: "PO",
        };
        let servicePO = {
          queryName: "getServicePO",
          module: "PO",
        };
        let schedulingAgreement = {
          queryName: "getSchedulingAgreement",
          module: "PO",
        };

        if (getHeaderData.purchaseTypeCode === "MAT") {
          dispatch(getPurchaseOrder(materialPO));
        } else if (getHeaderData.purchaseTypeCode === "SER") {
          dispatch(getServicePO(servicePO));
        } else if (getHeaderData.purchaseTypeCode === "SA") {
          dispatch(getSchedulingAgreement(schedulingAgreement));
        }
      } else {
        console.log("PO Acknowledged Not successfully");

        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
  };

  // Load GRN Historical data on page load
  const loadGrnHistoricalData = async () => {
    let obj = {
      page: 1,
      per_page: 10,
      purchasingDocumentNr: poNumber,
    };

    setLoading(true);
    await ItemService.getPOGrn(obj).then((item) => {
      // console.log(">> res! getPOGrn", item);
      if (item?.status === 200) {
        // toast.success(item?.message || "", {
        //   duration: 3000,
        //   position: "top-right",
        // });
        setGrnHistoricalData(item.data);
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    setLoading(false);
  };

  // Load ASN Historical data on page load
  const loadAsnHistoricalData = async () => {
    let obj = {
      page: 1,
      per_page: 10,
      purchasingDocumentNr: poNumber,
    };

    setLoading(true);
    await ItemService.getPOAsn(obj).then((item) => {
      // console.log(">> res! getPOAsn", item);
      if (item?.status === 200) {
        // toast.success(item?.message || "", {
        //   duration: 3000,
        //   position: "top-right",
        // });
        setAsnHistoricalData(item.data);
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    setLoading(false);
  };

  // Load Invoice Historical data on page load
  const loadInvoiceHistoricalData = async () => {
    let obj = {
      page: 1,
      per_page: 10,
      purchasingDocumentNr: poNumber,
    };

    setLoading(true);
    await ItemService.getPOInvoice(obj).then((item) => {
      // console.log(">> res! getPOInvoice", item);
      if (item?.status === 200) {
        // toast.success(item?.message || "", {
        //   duration: 3000,
        //   position: "top-right",
        // });
        setInvoiceHistoricalData(item.data);
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    setLoading(false);
  };

  // Load Ses Historical data on page load
  const loadSesHistoricalData = async () => {
    let obj = {
      page: 1,
      per_page: 10,
      purchasingDocumentNr: poNumber,
    };

    setLoading(true);
    await ItemService.getPOSes(obj).then((item) => {
      // console.log(">> res! getPOSes", item);
      if (item?.status === 200) {
        // toast.success(item?.message || "", {
        //   duration: 3000,
        //   position: "top-right",
        // });
        setSesHistoricalData(item.data);
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    setLoading(false);
  };

  //Selected from the Table
  const handleChange = ({ selectedRows }) => {
    setToggleCleared(false);
    setSelectedData(selectedRows);
    dispatch(addSelectedItemList(selectedRows));
    // if (selectedRows.length > 0) {
    //   setDisabled(false);
    // } else {
    //   setDisabled(true);
    // }
  };

  //Create ASN
  const createASN = () => {
    navigate(Constants.POCOLLABRATION_ASN_CREATION);
  };

  //Create SA ASN
  const createSAASN = () => {
    navigate(Constants.POCOLLABRATION_SA_ASN_CREATION);
  };

  //Create GRN
  const createGRN = () => {
    let obj = {
      asnNumber: "",
      subTransactionActivity: "PO",
    };
    dispatch(addSubTransactionActivity(obj));
    navigate(Constants.POCOLLABRATION_GRN_CREATION);
  };

  //Create SES
  const createSES = () => {
    let obj = {
      asnNumber: "",
      subTransactionActivity: "PO",
    };
    dispatch(addSubTransactionActivity(obj));
    navigate(Constants.POCOLLABRATION_SES_CREATION);
  };

  //Create Invoice
  const createInvoice = () => {
    navigate(Constants.POCOLLABRATION_INVOICE_CREATION);
  };

  useEffect(() => {
    if (
      workflow?.workflow_record?.businessComponentId ===
      getConstantVar?.POBusinessComponentId
    ) {
      localStorage.setItem("menu", workflow?.page);
      localStorage.setItem("submenu", "-");
    } else {
      //console.log(">> getHeaderData", getHeaderData);
      if (getHeaderData.purchaseTypeCode === "MAT") {
        localStorage.setItem("menu", "Purchasing");
        localStorage.setItem("submenu", "Purchase Order");
      } else if (getHeaderData.purchaseTypeCode === "SER") {
        localStorage.setItem("menu", "Purchasing");
        localStorage.setItem("submenu", "Service PO");
      } else if (getHeaderData.purchaseTypeCode === "SA") {
        localStorage.setItem("menu", "Purchasing");
        localStorage.setItem("submenu", "Scheduling Agreement");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateToWorkflow = () => {
    window.history.back();
  };

  const showApproveModal = async (name) => {
    setModalShow(true);
    setAction(name);
  };

  const workflowApproveReject = async (remarks) => {
    let obj = {};
    let bodyObj = workflow?.workflow_record?.workflowActionAPI?.Body;

    obj._id = workflow?.workflow_record?._id;
    obj.businessComponentId = workflow?.workflow_record?.businessComponentId;
    obj.eventId = workflow?.workflow_record?.eventId;
    obj.businessComponentInstanceId =
      workflow?.workflow_record?.businessComponentInstanceId;
    obj.stepId = workflow?.workflow_record?.stepId;
    obj.action = getAction;
    obj.remark = remarks;
    obj.editContextData = workflow?.workflow_record?.editContextData;
    obj.editForm = workflow?.workflow_record?.editForm;
    obj.data = {};
    let newObj = { ...bodyObj, ...obj };

    //Approve
    await ItemService.workflowApproveReject(newObj).then((item) => {
      // console.log(">> item!", item);
      if (item?.status === 200) {
        successModalData.successMsg =
          getAction === "Approve"
            ? "Approved Successfully !!"
            : "Rejected Successfully !!";
        setsucceModalData(successModalData);
        setNextNavigate(Constants.INBOX);
        onSuccessmodel();
      } else {
        console.log(">> Error: Loading workflowApproveReject");
      }
    });
  };

  const handleValueChange = (remarks) => {
    workflowApproveReject(remarks);
  };

  const showServiceItemTable = (serviceData, itemNr) => {
    setItemNr(itemNr);
    setServiceData(serviceData);
    setServiceTableStatus(true);
  };
  const hide = () => {
    setServiceTableStatus(false);
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">
              Order Details : {poNumber} 
              <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                {getHeaderData.purchaseType}
              </span>
              <span>
                {getHeaderData?.statusHeader === "New" ? (
                  <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                    {getHeaderData?.statusHeader}
                  </span>
                ) : getHeaderData?.statusHeader === "In Release" ? (
                  <span className="bg-table-status bg-table-progress-status headerStatusDetailedPage">
                    {getHeaderData?.statusHeader}
                  </span>
                ) : getHeaderData?.statusHeader === "Acknowledged" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {getHeaderData?.statusHeader}
                  </span>
                ) : getHeaderData?.statusHeader === "Cancelled" ? (
                  <span className="bg-table-status bg-table-danger-status headerStatusDetailedPage">
                    {getHeaderData?.statusHeader}
                  </span>
                ) : (
                  getHeaderData?.statusHeader
                )}
              </span>
            </h2>
            <p className="subText">Purchase Order Information </p>
            <hr />

            <Row className="mb-4">
              <Col xs={12} md={4} lg={4} className="text-left">
                <button
                  className="btnBack"
                  onClick={() => navigateToWorkflow()}
                >
                  <i className="bi bi-arrow-return-left"></i> Go Back
                </button>
              </Col>
              <Col xs={12} md={8} lg={8} className="text-right">
                {workflow?.workflow_record?.decision?.length > 0 &&
                  workflow?.workflow_record?.decision?.map((item, index) => (
                    <button
                      className={
                        item === "Approve" || item === "Submit"
                          ? "btnCommon btn-approve"
                          : "btnCommon btn-danger"
                      }
                      onClick={() => showApproveModal(item)}
                      key={index}
                    >
                      <i
                        className={
                          item === "Approve" || item === "Submit"
                            ? "bi bi-check2-circle"
                            : "bi bi-x-circle"
                        }
                      ></i>{" "}
                      {item}
                    </button>
                  ))}
              </Col>
            </Row>

            {/* Header Details */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">HR</span>
                    </Col>
                    <Col xs={12} md={2} lg={2}>
                      <span className="headerTitle">Header Details</span>
                      <span className="subHeaderTitle"> </span>
                    </Col>

                    {!workflow?.workflow_record?.businessComponentId && (
                      <>
                        {getHeaderData?.statusHeader === "Acknowledged" && (
                          <Col
                            xs={12}
                            md={8}
                            lg={8}
                            className="headerButtonAlign"
                          >
                            {config.createASN &&
                              getHeaderData.purchaseTypeCode === "SA" && (
                                <Col xs={4} md={2} lg={2} className="">
                                  <button
                                    className="btnTable"
                                    onClick={() => createSAASN()}
                                  >
                                    <i className="bi bi-calendar-check"></i>{" "}
                                    Create ASN
                                  </button>
                                </Col>
                              )}

                            {config.createGRN &&
                              getHeaderData.purchaseTypeCode === "MAT" && (
                                <>
                                  <Col xs={4} md={2} lg={2} className="">
                                    <button
                                      className="btnTable"
                                      onClick={() => createASN()}
                                    >
                                      <i className="bi bi-calendar-check"></i>{" "}
                                      Create ASN
                                    </button>
                                  </Col>
                                  <Col xs={4} md={2} lg={2} className="">
                                    <button
                                      className="btnTable"
                                      onClick={() => createGRN()}
                                    >
                                      <i className="bi bi-truck"></i> Create GRN
                                    </button>
                                  </Col>
                                </>
                              )}

                            {config.createSES &&
                              getHeaderData.purchaseTypeCode === "SER" && (
                                <Col xs={4} md={2} lg={2} className="">
                                  <button
                                    className="btnTable"
                                    onClick={() => createSES()}
                                  >
                                    <i className="bi bi-file-spreadsheet"></i>{" "}
                                    Create SES
                                  </button>
                                </Col>
                              )}

                            {config.createInvoice && (
                              <Col xs={4} md={2} lg={2} className="">
                                <button
                                  className="btnTable"
                                  onClick={() => createInvoice()}
                                >
                                  <i className="bi bi-currency-dollar"></i>{" "}
                                  Create Invoice
                                </button>
                              </Col>
                            )}
                          </Col>
                        )}
                      </>
                    )}
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Document Number"
                          name="purchasingDocumentNr"
                          placeholder=""
                          value={getHeaderData?.purchasingDocumentNr || ""}
                          disabled={true}
                        />
                      </Col>
                      {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Status"
                          name="statusHeader"
                          placeholder=""
                          value={getHeaderData?.statusHeader || ""}
                          disabled={true}
                        />
                      </Col> */}
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Document Date"
                          name="purchasingDocumentDate"
                          placeholder=""
                          value={getHeaderData?.purchasingDocumentDate || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Net Value"
                          name="purchasingDocumentValue"
                          placeholder=""
                          value={currencyFormat(
                            Number(
                              getHeaderData?.purchasingDocumentValue
                            ).toFixed(2)
                          )}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Currency"
                          name="currency"
                          placeholder=""
                          value={`${getHeaderData?.currencyCode} - ${getHeaderData?.currency}`}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Open Value"
                          name="openValue"
                          placeholder=""
                          value={getHeaderData?.openValue || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Delivered Value"
                          name="deliverdValue"
                          placeholder=""
                          value={getHeaderData?.deliverdValue || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Delivered Status"
                          name="deliveredStatus"
                          placeholder=""
                          value={getHeaderData?.deliveredStatus || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Invoiced Value"
                          name="invoicedValue"
                          placeholder=""
                          value={getHeaderData?.invoicedValue || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Invoiced Status"
                          name="invoicedStatus"
                          placeholder=""
                          value={getHeaderData?.invoicedStatus || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Paid Value"
                          name="paidValue"
                          placeholder=""
                          value={getHeaderData?.paidValue || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Vendor Code"
                          name="vendorCode"
                          placeholder=""
                          value={getHeaderData?.vendorCode || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Vendor Name"
                          name="vendorName"
                          placeholder=""
                          value={getHeaderData?.vendorName || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Payment Terms"
                          name="paymentTerms"
                          placeholder=""
                          value={getHeaderData?.paymentTerms || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Inco Terms"
                          name="incoTerms"
                          placeholder=""
                          value={getHeaderData?.incoTerms || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Company Code "
                          name="companyCodeCode"
                          id="companyCodeCode"
                          value={getHeaderData?.companyCodeCode}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Purchse Type"
                          name="purchasingRemark"
                          placeholder=""
                          value={getHeaderData?.purchaseType || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding mt-4 pt-3"
                      >
                        <div>
                          <label className="block text-sm font-medium leading-6 text-gray-900 infoIconAccordion">
                            Header Text
                          </label>
                          <span
                            style={{ marginLeft: "15px" }}
                            onClick={() =>
                              headerTextShowModal(getHeaderData?.headerTextData)
                            }
                          >
                            <i className="bi bi-calendar2-week iconTable"></i>
                          </span>
                          <span className="suppliersIcon">
                            {" "}
                            {getHeaderData?.headerTextData?.length || 0}{" "}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Line Itemss */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">LI</span>
                    </Col>
                    <Col xs={12} md={3} lg={3}>
                      <span className="headerTitle">Line Items</span>
                      <span className="subHeaderTitle"> </span>
                    </Col>

                    <Col xs={12} md={8} lg={8} className="headerButtonAlign">
                      {userInfo?.roles?.length > 0 &&
                        (userInfo?.roles[0].roleId === "VENDOR"
                          ? true
                          : false) &&
                        getHeaderData?.statusHeader !== "Acknowledged" && (
                          <>
                            <Col xs={4} md={2} lg={2} className="">
                              <button
                                className="btnTable"
                                onClick={() => handleAcknowledgeClick()}
                                disabled={disabled}
                              >
                                <i className="bi bi-check-circle"></i>{" "}
                                Acknowledge
                              </button>
                            </Col>
                          </>
                        )}

                      <Col xs={2} md={2} lg={2} className="">
                        {getServiceTableStatus ? (
                          <button className="btn btn-danger" onClick={hide}>
                            <i className="bi bi-eye-slash-fill"></i> Hide
                            Serivces
                          </button>
                        ) : (
                          " "
                        )}
                      </Col>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={
                            getHeaderData.purchaseTypeCode === "MAT"
                              ? columns
                              : getHeaderData.purchaseTypeCode === "SER"
                              ? columnsService
                              : getHeaderData.purchaseTypeCode === "SA"
                              ? columnsSchedule
                              : columns
                          }
                          data={data}
                          progressPending={loading}
                          pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          onSelectedRowsChange={handleChange}
                          clearSelectedRows={toggleCleared}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Service Itemss */}
            {getServiceTableStatus && (
              <div
                ref={divRef}
                className="accordion mb-4"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible2
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility2}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">LI</span>
                      </Col>
                      <Col xs={12} md={9} lg={9}>
                        <span className="headerTitle">
                          {getHeaderData.purchaseTypeCode === "SER"
                            ? `Services - PO Item: ${getItemNr}`
                            : getHeaderData.purchaseTypeCode === "SA"
                            ? `Schedules - SA Item: ${getItemNr}`
                            : ""}
                        </span>
                        <span className="subHeaderTitle"> </span>
                      </Col>

                      {/* <Col xs={2} md={1} lg={1} className="">
                        <div className="filter">
                          <span
                            className="icon hide headerIcon "
                            data-bs-toggle="dropdown"
                            aria-expanded="true"
                            onClick={()=>hide()}
                          >
                            <i className="bi bi-x-circle"></i>
                          </span>
                        </div>
                      </Col> */}
                    </Row>
                  </h2>

                  <div
                    // id="acc"
                    className={`accordion-collapse collapse show ${
                      isCardVisible2 ? "visible" : ""
                    }`}
                    // aria-labelledby="headingOne"
                    // data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <DataTable
                            title=""
                            columns={
                              getHeaderData.purchaseTypeCode === "SER"
                                ? serviceColInfo
                                : getHeaderData.purchaseTypeCode === "SA"
                                ? scheduleColInfo
                                : ""
                            }
                            data={getServiceData}
                            progressPending={loading}
                            pagination
                            // paginationServer
                            // paginationTotalRows={totalRows}
                            // onChangeRowsPerPage={handlePerRowsChange}
                            // onChangePage={handlePageChange}
                            selectableRows
                            paginationRowsPerPageOptions={
                              Constants.ROW_PER_PAGE
                            }
                            onSelectedRowsChange={handleChange}
                            clearSelectedRows={toggleCleared}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Historical ASN List */}
            {getAsnHistoricalData.length > 0 && (
              <>
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible4
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility4}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">SL</span>
                        </Col>
                        <Col xs={5} md={8} lg={8}>
                          <span className="headerTitle">Historical ASN</span>
                          <span className="subHeaderTitle">
                            View of ASN List{" "}
                          </span>
                        </Col>

                        {/* <Col xs={2} md={1} lg={1} className="">
                          <POCollabrationCommonFillter />
                        </Col> */}
                      </Row>
                    </h2>

                    <div
                      // id="acc"
                      className={`accordion-collapse collapse show ${
                        isCardVisible4 ? "visible" : ""
                      }`}
                      // aria-labelledby="headingOne"
                      // data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <Row>
                          <Col xs={12} md={12} lg={12}>
                            <DataTable
                              title=""
                              columns={asnHistoryColInfo}
                              data={getAsnHistoricalData}
                              progressPending={loading}
                              paginationRowsPerPageOptions={
                                Constants.ROW_PER_PAGE
                              }
                              pagination
                              // paginationServer
                              // paginationTotalRows={totalRows}
                              // onChangeRowsPerPage={handlePerRowsChange}
                              // onChangePage={handlePageChange}
                              selectableRows
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* Historical Goods Received Note List */}
            {getGrnHistoricalData.length > 0 && (
              <>
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible3
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility3}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">SL</span>
                        </Col>
                        <Col xs={12} md={8} lg={8}>
                          <span className="headerTitle">Historical GRN</span>
                          <span className="subHeaderTitle">
                            View of Goods Received Note List{" "}
                          </span>
                        </Col>

                        {/* <Col xs={2} md={1} lg={1} className="">
                          <POCollabrationCommonFillter />
                        </Col> */}
                      </Row>
                    </h2>

                    <div
                      // id="acc"
                      className={`accordion-collapse collapse show ${
                        isCardVisible3 ? "visible" : ""
                      }`}
                      // aria-labelledby="headingOne"
                      // data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <Row>
                          <Col xs={12} md={12} lg={12}>
                            <DataTable
                              title=""
                              columns={grnHistoryColInfo}
                              data={getGrnHistoricalData}
                              progressPending={loading}
                              paginationRowsPerPageOptions={
                                Constants.ROW_PER_PAGE
                              }
                              pagination
                              // paginationServer
                              // paginationTotalRows={totalRows}
                              // onChangeRowsPerPage={handlePerRowsChange}
                              // onChangePage={handlePageChange}
                              selectableRows
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* Historical Invoice List */}
            {getInvoiceHistoricalData.length > 0 && (
              <>
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible3
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility3}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">SL</span>
                        </Col>
                        <Col xs={12} md={8} lg={8}>
                          <span className="headerTitle">
                            Historical Invoice
                          </span>
                          <span className="subHeaderTitle">
                            View of Invoice List{" "}
                          </span>
                        </Col>

                        {/* <Col xs={2} md={1} lg={1} className="">
                          <POCollabrationCommonFillter />
                        </Col> */}
                      </Row>
                    </h2>

                    <div
                      // id="acc"
                      className={`accordion-collapse collapse show ${
                        isCardVisible3 ? "visible" : ""
                      }`}
                      // aria-labelledby="headingOne"
                      // data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <Row>
                          <Col xs={12} md={12} lg={12}>
                            <DataTable
                              title=""
                              columns={invoiceDataColumns}
                              data={getInvoiceHistoricalData}
                              progressPending={loading}
                              paginationRowsPerPageOptions={
                                Constants.ROW_PER_PAGE
                              }
                              pagination
                              // paginationServer
                              // paginationTotalRows={totalRows}
                              // onChangeRowsPerPage={handlePerRowsChange}
                              // onChangePage={handlePageChange}
                              selectableRows
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* Historical SES List */}
            {getSesHistoricalData.length > 0 && (
              <>
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible3
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility3}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">SL</span>
                        </Col>
                        <Col xs={12} md={8} lg={8}>
                          <span className="headerTitle">Historical SES</span>
                          <span className="subHeaderTitle">
                            View of SES List{" "}
                          </span>
                        </Col>

                        {/* <Col xs={2} md={1} lg={1} className="">
                          <POCollabrationCommonFillter />
                        </Col> */}
                      </Row>
                    </h2>

                    <div
                      // id="acc"
                      className={`accordion-collapse collapse show ${
                        isCardVisible3 ? "visible" : ""
                      }`}
                      // aria-labelledby="headingOne"
                      // data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <Row>
                          <Col xs={12} md={12} lg={12}>
                            <DataTable
                              title=""
                              columns={sesHistoryColInfo}
                              data={getSesHistoricalData}
                              progressPending={loading}
                              paginationRowsPerPageOptions={
                                Constants.ROW_PER_PAGE
                              }
                              pagination
                              // paginationServer
                              // paginationTotalRows={totalRows}
                              // onChangeRowsPerPage={handlePerRowsChange}
                              // onChangePage={handlePageChange}
                              selectableRows
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>

      {/* Item Text  */}
      <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />

      {/* Header Text  */}
      <CommonItemLevelDatatableModal
        show={getViewHeaderText}
        onHide={() => headerTextHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />

      <ApproveRemarksModal
        show={modalShow}
        onHide={() => hideModal()}
        onvaluechange={handleValueChange}
      />

      {/* Success Modal */}
      <SuccessModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        successmodaldata={getsucceModalData}
        nextnavigate={getNextNavigate}
      />

      {/* ASN Item Text  */}
      <CommonItemLevelDatatableModal
        show={getASNitemLevelModalShow}
        onHide={() => ASNItemLevelHideModal()}
        columns={ASNLineItemColInfo}
        data={getItemLevelData}
        title={getModalTitle}
      />

      {/* GRN Item Text  */}
      <CommonItemLevelDatatableModal
        show={getGRNitemLevelModalShow}
        onHide={() => GRNLItemLevelHideModal()}
        columns={GRNLineItemColInfo}
        data={getItemLevelData}
        title={getModalTitle}
      />

      {/* Invoice Item Text  */}
      <CommonItemLevelDatatableModal
        show={getInvoiceitemLevelModalShow}
        onHide={() => InvoiceItemLevelHideModal()}
        columns={InvoiceLineItemColInfo}
        data={getItemLevelData}
        title={getModalTitle}
      />

      {/* Invoice Item Text  */}
      <CommonItemLevelDatatableModal
        show={getSESitemLevelModalShow}
        onHide={() => SESLItemLevelHideModal()}
        columns={SESLineItemColInfo}
        data={getItemLevelData}
        title={getModalTitle}
      />
    </main>
  );
};

export default PurchaseOrderDetailedPage;
