/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'   
import { Routes, Route, Navigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';

import ConstantsList from './common/Constants';

// import Layout  from './Layout/Layout';
import Page404 from './Pages/404Page';
import Login from './Pages/Login/Login';
import Register from './Pages/Login/Register';

// supplier_app Starts
import HOME from './Pages/supplier_app';
import HOME_INTRO from './Pages/supplier_app/HomeIntro/HomeIntro';
import DASHBOARD from './Pages/supplier_app/Dashboard';
import INBOX from './Pages/supplier_app/Inbox';
import OUTBOX from './Pages/supplier_app/Outbox';
                
import SUPPLIER_SELF_REGISTRATION from './Pages/supplier_app/Supplier/SupplierMaster/SupplierSelfRegistration';
import SUPPILER_REGISTRATION from './Pages/supplier_app/Supplier/SupplierMaster/SupplierRegistration';
import SUPPILER_LIST from './Pages/supplier_app/Supplier/SupplierMaster/SupplierList';
import SUPPILER_MASS_UPLOAD from './Pages/supplier_app/Supplier/SupplierMassUpload';
import SUPPLIER_MASTER_CREATE from './Pages/supplier_app/Supplier/SupplierMaster/SupplierMasterCreate';
import SUPPLIER_DETAILED_PAGE_CREATE from './Pages/supplier_app/Supplier/SupplierMaster/SupplierDetailedPage';
import SUPPLIER_DETAILED_PAGE_WORKFLOW from './Pages/supplier_app/Supplier/SupplierMaster/SupplierDetailedPageWorkflow';

import SOURCING_PURCHASE_REQUISITION_LIST from './Pages/supplier_app/Sourcing/PR/PurchaseRequisitionList';
import SOURCING_VENDOR_REQUEST_FOR_QUOTATION from './Pages/supplier_app/Sourcing/Supplier/VendorRequestForQuotationList';
import SOURCING_VENDOR_RFQ_DETAILS from './Pages/supplier_app/Sourcing/Supplier/VendorRFQDetails'
import SOURCING_VENDOR_QUOTATION_LIST from './Pages/supplier_app/Sourcing/Supplier/VendorQuotationList';
import SOURCING_BUYER_REQUEST_FOR_QUOTATION from './Pages/supplier_app/Sourcing/Buyer/BuyerRequestForQuotationList';
import SOURCING_BUYER_RFQ_DETAILS from './Pages/supplier_app/Sourcing/Buyer/BuyerRFQDetails'
import SOURCING_BUYER_QUOTATION_LIST from './Pages/supplier_app/Sourcing/Buyer/BuyerQuotationList';
import SOURCING_CREATE_RFQ from './Pages/supplier_app/Sourcing/Buyer/CreateRFQ';
import SOURCING_CREATE_MANUAL_RFQ from './Pages/supplier_app/Sourcing/Buyer/CreateManualRFQ';
import SOURCING_CREATE_QUOTATION from './Pages/supplier_app/Sourcing/Supplier/CreateQuotation';
import SOURCING_QUOTATION_DETAILS from './Pages/supplier_app/Sourcing/Supplier/QuotationDetailPage';

import SOURCING_PURCHASE_REQUISITION_CREATE from './Pages/supplier_app/Sourcing/PR/CreatePR';
import SOURCING_PURCHASE_REQUISITION_DETAILED_PAGE from './Pages/supplier_app/Sourcing/PR/PurchaseRequisitionDetailedPage';
import SOURCING_COMMERCIAL_EVALUATION from './Pages/supplier_app/Sourcing/CommercialEvaluation'
import SOURCING_TECHNICAL_EVALUATION from './Pages/supplier_app/Sourcing/TechnicalEvaluation'
import SOURCING_PURCHASE_ORDER_CREATION from './Pages/supplier_app/Sourcing/CreatePO'

import POCOLLABRATION_PURCHASE_ORDER_LIST from './Pages/supplier_app/POCollabration/PO/PurchaseOrderList';
import POCOLLABRATION_SERVICE_PURCHASE_ORDER_LIST from './Pages/supplier_app/POCollabration/PO/ServicePurchaseOrderList';
import POCOLLABRATION_PURCHASE_ORDER_CREATION from './Pages/supplier_app/POCollabration/PO/CreatePO';
import POCOLLABRATION_PURCHASE_ORDER_DETAIL_PAGE from './Pages/supplier_app/POCollabration/PO/PurchaseOrderDetailPage';
import POCOLLABRATION_SERVICE_ENTRY_SHEET  from './Pages/supplier_app/POCollabration/SES/ServiceEntrySheetList';
import POCOLLABRATION_INVOICE  from './Pages/supplier_app/POCollabration/INVOICE/InvoiceList';
import POCOLLABRATION_INVOICE_DETIAILS  from './Pages/supplier_app/POCollabration/INVOICE/InvoiceDetails';
import POCOLLABRATION_PAYMENT_LIST  from './Pages/supplier_app/POCollabration/PAYMENT/PaymentList';
import POCOLLABRATION_ASN from './Pages/supplier_app/POCollabration/ASN/ASNList';
import POCOLLABRATION_ASN_DETAIL_PAGE from './Pages/supplier_app/POCollabration/ASN/ASNDetailPage';
import POCOLLABRATION_ASN_CREATION from './Pages/supplier_app/POCollabration/ASN/CreateASN'
import POCOLLABRATION_SA_ASN_CREATION from './Pages/supplier_app/POCollabration/ASN/CreateSAASN'
import POCOLLABRATION_GRN_CREATION from './Pages/supplier_app/POCollabration/GRN/CreateGRN'
import POCOLLABRATION_GRN_DETAILS from './Pages/supplier_app/POCollabration/GRN/GRNDetails'
import POCOLLABRATION_GOODS_RECEIVED_NOTE  from './Pages/supplier_app/POCollabration/GRN/GoodsReceivedNoteList';
import POCOLLABRATION_SES_CREATION from './Pages/supplier_app/POCollabration/SES/CreateSES'
import POCOLLABRATION_INVOICE_CREATION from './Pages/supplier_app/POCollabration/INVOICE/CreateInvoice'
import POCOLLABRATION_SCHEDULING_AGREEMENT from './Pages/supplier_app/POCollabration/PO/SchedulingAgreement';
import POCOLLABRATION_SERVICE_ENTRY_DETAILS from './Pages/supplier_app/POCollabration/SES/ServiceEntrySheetDetail';



import SAPREPROCESS_GRN_REPROCESS  from './Pages/supplier_app/SAPReprocess/GRNReprocess';
import SAPREPROCESS_SES_REPROCESS from './Pages/supplier_app/SAPReprocess/SESReprocess';
import SAPREPROCESS_INVOICE_REPROCESS from './Pages/supplier_app/SAPReprocess/InvoiceReprocess';

import  ADMIN_MANAGE_USER_LIST  from './Pages/supplier_app/Admin/ManageUserList';
import  ADMIN_MANAGE_ROLE from './Pages/supplier_app/Admin/ManageRole';
import  ADMIN_MANAGE_ADD_USER from './Pages/supplier_app/Admin/ManageAddUser';
import  ADMIN_MANAGE_EDIT_USER from './Pages/supplier_app/Admin/ManageEditUser';
import  ADMIN_MANAGE_USER_LIST_DETAILS from './Pages/supplier_app/Admin/ManageUserDetails';


import AUCTION_REVERSE_AUCTION_LIST from './Pages/supplier_app/Auction/ReverseAuction';
import AUCTION_REVERSE_AUCTION_CREATION from './Pages/supplier_app/Auction/CreateAuction';
import AUCTION_REVERSE_AUCTION_HISTORY from './Pages/supplier_app/Auction/ReverseAuctionHistory';
import AUCTION_FORWARD_AUCTION_LIST from './Pages/supplier_app/Auction/ForwardAuction';
import AUCTION_FORWARD_AUCTION_HISTORY from './Pages/supplier_app/Auction/ForwardAuctionHistory';

import CONTRACTMANAGEMENT_CONTRACT_GENERATE_LIST from './Pages/supplier_app/ContractManagement/ContractList';
import CONTRACTMANAGEMENT_CONTRACT_REVIEW_LIST from './Pages/supplier_app/ContractManagement/ContractReviewer';
import CONTRACTMANAGEMENT_CONTRACT_DRAFT_TO_FINAL_LIST from './Pages/supplier_app/ContractManagement/ContractDrafttoFinal';
import CONTRACTMANAGEMENT_MONITORING_LIST from './Pages/supplier_app/ContractManagement/ContractMonitoring';


import GATEPASS_GATEPASS_LIST from './Pages/supplier_app/GatePass/GatepassList';
import GATEPASS_GATEPASS_CREATE from './Pages/supplier_app/GatePass/GatepassCreation';
import GATEPASS_GATEPASS_DETAIL_PAGE from './Pages/supplier_app/GatePass/GatepassDetailPage';

import USER_MANUAL_BUYER_MANUAL from './Pages/supplier_app/UserManual/BuyerManual';


/* Delegation */
import DELEGATION_TASK from './Pages/supplier_app/Delegation/TaskDelegation';
import DELEGATION_USER from './Pages/supplier_app/Delegation/UserDelegation';

/*SINGLE SOURCING*/
import SINGLESOURCING_SINGLE_SOURCING_CREATION from './Pages/supplier_app/SingleSourcing/CreateSingleSource';
import SINGLESOURCING_SINGLE_SOURCING_LIST from './Pages/supplier_app/SingleSourcing/SingleSourceList';
import SINGLESOURCING_SINGLE_SOURCING_DETAILS from './Pages/supplier_app/SingleSourcing/SingleSourceDetails';

/*ADVANCE PAYMENT */
import ADVANCE_PAYMENT_ADVANCE_PAYMENT_REQUEST_CREATION from './Pages/supplier_app/AdvancePayment/AdvancePaymentRequestCreate';
import ADVANCE_PAYMENT_ADVANCE_PAYMENT_REQUEST_LIST from './Pages/supplier_app/AdvancePayment/AdvancePaymentRequestList';
import ADVANCE_PAYMENT_ADVANCE_PAYMENT_REQUEST_DETAILS from './Pages/supplier_app/AdvancePayment/AdvancePaymentRequestDetails';

/*GHG - GREEN HOUS GAS*/
import GHG_SCOPE1_CREATION from './Pages/supplier_app/GHG/CreateGHGScop1';

// supplier_app Ends

import Company from './Pages/Settings/Company';
import Profile from './Pages/Settings/Profile';
// import Test from './Pages/Test/Test';

//Redux
import { useSelector, useDispatch } from "react-redux";
import { getLoginUserData, getUsersListData} from './redux/feature/userSlice';
import { getRfqQuotation, getPurchaseRequisition, getQuotation } from './redux/feature/supplier_app/sourcingSlice';
import { getSupplierList} from './redux/feature/supplier_app/supplierSlice';
import { getPurchaseOrder, getGoodsReceivedNote, getASN, getInvoiceList, getSesList, getSchedulingAgreement, getServicePO } from './redux/feature/supplier_app/purchasingSlice';
import { getGatepassList} from './redux/feature/supplier_app/gatepassSlice';

import { getMenuOnUserRole } from './redux/feature/supplier_app/menuSlice';
import { getConstantVariable } from './redux/feature/supplier_app/commonSlice';

import { getSingleSourcingList } from './redux/feature/supplier_app/singleSourcingSlice';

// Assume this is your authentication check function
const isAuthenticated = () => {
    // Check if the token is present or valid
    const token = localStorage.getItem('token');
    return !!token;
};

const PrivateRoute = ({ children }) => {
    // const location = useLocation();
    // let menuData = useSelector((state) => state?.menu.data); // User Details from Store
    console.log(">> RouterNav >> user isAuthenticated >> " , isAuthenticated());

    // let check = checkHrefLink(menuData, location.pathname);
    // console.log(">> check", check);
    // console.log(">> location.pathname", location.pathname);
    // if(check){
    //     return isAuthenticated() ? children : <Navigate to="/login" />;
    // }else{

    //     return isAuthenticated() ? <Navigate to="/home" /> : <Navigate to="/login" />;
    // }    

    return isAuthenticated() ? children : <Navigate to="/login" />;
};

// const checkHrefLink = (arr, url) => {
//     for (let item of arr) {
//         if (item.hrefLink === url) {
//             return true;
//         }
//         if (item.children) {
//             const foundInChildren = checkHrefLink(item.children, url);
//             if (foundInChildren) return true;
//         }
//     }
//     return false;
// };

  
function RouterNav() {
    const { t, i18n } = useTranslation();
    // console.log(">> RouterNav >> ");
    const dispatch = useDispatch();

    useEffect(() => {
        
        changeLanguage("en"); // kannada, tamil, en, ae
        console.log(">> lang:", t('msg_name'));

        let localData = localStorage.getItem('userData');
        let userData={}
        if(localData){
            userData = JSON.parse(localData);

            let materialPO = {
                "queryName": "getMaterialPO",
                "module":"PO"
            }
            let servicePO = {
                "queryName": "getServicePO",
                "module":"PO"
            }
            let schedulingAgreement = {
                "queryName": "getSchedulingAgreement",
                "module":"PO"
            }
            
            dispatch(getConstantVariable({}));    // Constant Variable store
            dispatch(getMenuOnUserRole({}));    // Menu On User Role store
            dispatch(getLoginUserData({}));    // Login User store      

            // - SOURCING
            dispatch(getRfqQuotation({}));    // RfqQuotation store       
            dispatch(getPurchaseRequisition({}));    // getPurchaseRequisition store     
            dispatch(getQuotation({}));    // getPurchaseRequisition store     
            
            // - SUPPILER
            dispatch(getSupplierList({}));    // getSupplierList store     
              
            // - PURCHASING
            dispatch(getPurchaseOrder(materialPO));    // getPurchaseOrder  servicePO store      
            dispatch(getServicePO(servicePO));    // getPurchaseOrder materialPO store      
            dispatch(getSchedulingAgreement(schedulingAgreement));    // getPurchaseOrder getSchedulingAgreement store      

            dispatch(getASN({}));    // getASN store  
            dispatch(getGoodsReceivedNote({}));    // getGoodsReceivedNote store  
            dispatch(getInvoiceList({}));    // getInvoiceList store 
            dispatch(getSesList({}));    // getSesList store 

            // - GATEPASS
            dispatch(getGatepassList({}));    // getGatepassList store   

            // - USER
            dispatch(getUsersListData({}));    // getGatepassList store      
            
            // - Single Sourcing List
            dispatch(getSingleSourcingList({}));    // getSingleSourcingList store 
        }  
        
    // eslint-disable-next-line          
    },[]);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
     };

    return(
        <>  
            {/* <Page404/> */}
            <Routes>                                      
                {/* Public route */}
                <Route exact path='*' element={<Page404 />}></Route>
                <Route exact path='/' element={<Login />}></Route>
                
                <Route  exact path={ConstantsList.SUPPLIER_MASTER_CREATE}  element={ <SUPPLIER_MASTER_CREATE/> } />
                <Route  exact path={ConstantsList.SUPPLIER_SELF_REGISTRATION}  element={ <SUPPLIER_SELF_REGISTRATION/>  } />

                <Route exact path={ConstantsList.LOGIN} element={<Login/>}></Route>                
                <Route exact path={ConstantsList.REGISTER} element={<Register/>}></Route>     

                {/* Private route */}
                <Route  exact path={ConstantsList.COMPANY}  element={ <PrivateRoute>  <Company/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.PROFILE}  element={ <PrivateRoute> <Profile/>  </PrivateRoute>  } />

                {/* supplier_app Starts here */}
                <Route  exact path={ConstantsList.HOME}  element={ <PrivateRoute> <HOME/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.HOME_INTRO}  element={ <PrivateRoute> <HOME_INTRO/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.DASHBOARD}  element={ <PrivateRoute> <DASHBOARD/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.INBOX}  element={ <PrivateRoute> <INBOX/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.OUTBOX}  element={ <PrivateRoute> <OUTBOX/>  </PrivateRoute>  } />
                

                <Route  exact path={ConstantsList.SUPPILER_REGISTRATION}  element={ <PrivateRoute> <SUPPILER_REGISTRATION/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.SUPPILER_LIST}  element={ <PrivateRoute> <SUPPILER_LIST/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.SUPPILER_MASS_UPLOAD}  element={ <PrivateRoute> <SUPPILER_MASS_UPLOAD/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.SUPPLIER_DETAILED_PAGE_CREATE}  element={ <PrivateRoute> <SUPPLIER_DETAILED_PAGE_CREATE/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.SUPPLIER_DETAILED_PAGE_WORKFLOW}  element={ <PrivateRoute> <SUPPLIER_DETAILED_PAGE_WORKFLOW/>  </PrivateRoute>  } />

                <Route  exact path={ConstantsList.SOURCING_PURCHASE_REQUISITION_LIST}  element={ <PrivateRoute> <SOURCING_PURCHASE_REQUISITION_LIST/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.SOURCING_PURCHASE_REQUISITION_CREATE}  element={ <PrivateRoute> <SOURCING_PURCHASE_REQUISITION_CREATE/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.SOURCING_PURCHASE_REQUISITION_DETAILED_PAGE}  element={ <PrivateRoute> <SOURCING_PURCHASE_REQUISITION_DETAILED_PAGE/>  </PrivateRoute>  } />
                
                <Route  exact path={ConstantsList.SOURCING_VENDOR_REQUEST_FOR_QUOTATION}  element={ <PrivateRoute> <SOURCING_VENDOR_REQUEST_FOR_QUOTATION/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.SOURCING_VENDOR_QUOTATION_LIST}  element={ <PrivateRoute> <SOURCING_VENDOR_QUOTATION_LIST/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.SOURCING_VENDOR_RFQ_DETAILS}  element={ <PrivateRoute> <SOURCING_VENDOR_RFQ_DETAILS/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.SOURCING_CREATE_QUOTATION}  element={ <PrivateRoute> <SOURCING_CREATE_QUOTATION/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.SOURCING_QUOTATION_DETAILS}  element={ <PrivateRoute> <SOURCING_QUOTATION_DETAILS/>  </PrivateRoute>  } />

                <Route  exact path={ConstantsList.SOURCING_BUYER_REQUEST_FOR_QUOTATION}  element={ <PrivateRoute> <SOURCING_BUYER_REQUEST_FOR_QUOTATION/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.SOURCING_BUYER_QUOTATION_LIST}  element={ <PrivateRoute> <SOURCING_BUYER_QUOTATION_LIST/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.SOURCING_BUYER_RFQ_DETAILS}  element={ <PrivateRoute> <SOURCING_BUYER_RFQ_DETAILS/>  </PrivateRoute>  } />      
                <Route  exact path={ConstantsList.SOURCING_CREATE_RFQ}  element={ <PrivateRoute> <SOURCING_CREATE_RFQ/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.SOURCING_CREATE_MANUAL_RFQ}  element={ <PrivateRoute> <SOURCING_CREATE_MANUAL_RFQ/>  </PrivateRoute>  } />

                <Route  exact path={ConstantsList.SOURCING_COMMERCIAL_EVALUATION}  element={ <PrivateRoute> <SOURCING_COMMERCIAL_EVALUATION/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.SOURCING_TECHNICAL_EVALUATION}  element={ <PrivateRoute> <SOURCING_TECHNICAL_EVALUATION/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.SOURCING_PURCHASE_ORDER_CREATION}  element={ <PrivateRoute> <SOURCING_PURCHASE_ORDER_CREATION/>  </PrivateRoute>  } />

                <Route  exact path={ConstantsList.POCOLLABRATION_SERVICE_PURCHASE_ORDER_LIST}  element={ <PrivateRoute> <POCOLLABRATION_SERVICE_PURCHASE_ORDER_LIST/>  </PrivateRoute>  } />      
                <Route  exact path={ConstantsList.POCOLLABRATION_PURCHASE_ORDER_LIST}  element={ <PrivateRoute> <POCOLLABRATION_PURCHASE_ORDER_LIST/>  </PrivateRoute>  } />  
                <Route  exact path={ConstantsList.POCOLLABRATION_PURCHASE_ORDER_CREATION}  element={ <PrivateRoute> <POCOLLABRATION_PURCHASE_ORDER_CREATION/>  </PrivateRoute>  } />      
                <Route  exact path={ConstantsList.POCOLLABRATION_GOODS_RECEIVED_NOTE}  element={ <PrivateRoute> <POCOLLABRATION_GOODS_RECEIVED_NOTE/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.POCOLLABRATION_SERVICE_ENTRY_SHEET}  element={ <PrivateRoute> <POCOLLABRATION_SERVICE_ENTRY_SHEET/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.POCOLLABRATION_INVOICE}  element={ <PrivateRoute> <POCOLLABRATION_INVOICE/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.POCOLLABRATION_INVOICE_DETIAILS}  element={ <PrivateRoute> <POCOLLABRATION_INVOICE_DETIAILS/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.POCOLLABRATION_PAYMENT_LIST}  element={ <PrivateRoute> <POCOLLABRATION_PAYMENT_LIST/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.POCOLLABRATION_PURCHASE_ORDER_DETAIL_PAGE}  element={ <PrivateRoute> <POCOLLABRATION_PURCHASE_ORDER_DETAIL_PAGE/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.POCOLLABRATION_ASN}  element={ <PrivateRoute> <POCOLLABRATION_ASN/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.POCOLLABRATION_ASN_DETAIL_PAGE}  element={ <PrivateRoute> <POCOLLABRATION_ASN_DETAIL_PAGE/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.POCOLLABRATION_ASN_CREATION}  element={ <PrivateRoute> <POCOLLABRATION_ASN_CREATION/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.POCOLLABRATION_SA_ASN_CREATION}  element={ <PrivateRoute> <POCOLLABRATION_SA_ASN_CREATION/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.POCOLLABRATION_GRN_CREATION}  element={ <PrivateRoute> <POCOLLABRATION_GRN_CREATION/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.POCOLLABRATION_GRN_DETAILS}  element={ <PrivateRoute> <POCOLLABRATION_GRN_DETAILS/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.POCOLLABRATION_SES_CREATION}  element={ <PrivateRoute> <POCOLLABRATION_SES_CREATION/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.POCOLLABRATION_INVOICE_CREATION}  element={ <PrivateRoute> <POCOLLABRATION_INVOICE_CREATION/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.POCOLLABRATION_SCHEDULING_AGREEMENT}  element={ <PrivateRoute> <POCOLLABRATION_SCHEDULING_AGREEMENT/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.POCOLLABRATION_SERVICE_ENTRY_DETAILS}  element={ <PrivateRoute> <POCOLLABRATION_SERVICE_ENTRY_DETAILS/>  </PrivateRoute>  } />

                <Route  exact path={ConstantsList.SAPREPROCESS_GRN_REPROCESS}  element={ <PrivateRoute> <SAPREPROCESS_GRN_REPROCESS/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.SAPREPROCESS_SES_REPROCESS}  element={ <PrivateRoute> <SAPREPROCESS_SES_REPROCESS/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.SAPREPROCESS_INVOICE_REPROCESS}  element={ <PrivateRoute> <SAPREPROCESS_INVOICE_REPROCESS/>  </PrivateRoute>  } />


                <Route  exact path={ConstantsList.ADMIN_MANAGE_ROLE}  element={ <PrivateRoute> <ADMIN_MANAGE_ROLE/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.ADMIN_MANAGE_USER_LIST}  element={ <PrivateRoute> <ADMIN_MANAGE_USER_LIST/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.ADMIN_MANAGE_ADD_USER}  element={ <PrivateRoute> <ADMIN_MANAGE_ADD_USER/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.ADMIN_MANAGE_EDIT_USER}  element={ <PrivateRoute> <ADMIN_MANAGE_EDIT_USER/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.ADMIN_MANAGE_USER_LIST_DETAILS}  element={ <PrivateRoute> <ADMIN_MANAGE_USER_LIST_DETAILS/>  </PrivateRoute>  } />

                <Route  exact path={ConstantsList.AUCTION_REVERSE_AUCTION_LIST}  element={ <PrivateRoute> <AUCTION_REVERSE_AUCTION_LIST/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.AUCTION_REVERSE_AUCTION_CREATION}  element={ <PrivateRoute> <AUCTION_REVERSE_AUCTION_CREATION/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.AUCTION_REVERSE_AUCTION_HISTORY}  element={ <PrivateRoute> <AUCTION_REVERSE_AUCTION_HISTORY/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.AUCTION_FORWARD_AUCTION_LIST}  element={ <PrivateRoute> <AUCTION_FORWARD_AUCTION_LIST/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.AUCTION_FORWARD_AUCTION_HISTORY}  element={ <PrivateRoute> <AUCTION_FORWARD_AUCTION_HISTORY/>  </PrivateRoute>  } />

                <Route  exact path={ConstantsList.CONTRACTMANAGEMENT_CONTRACT_GENERATE_LIST}  element={ <PrivateRoute> <CONTRACTMANAGEMENT_CONTRACT_GENERATE_LIST/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.CONTRACTMANAGEMENT_CONTRACT_REVIEW_LIST}  element={ <PrivateRoute> <CONTRACTMANAGEMENT_CONTRACT_REVIEW_LIST/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.CONTRACTMANAGEMENT_CONTRACT_DRAFT_TO_FINAL_LIST}  element={ <PrivateRoute> <CONTRACTMANAGEMENT_CONTRACT_DRAFT_TO_FINAL_LIST/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.CONTRACTMANAGEMENT_MONITORING_LIST}  element={ <PrivateRoute> <CONTRACTMANAGEMENT_MONITORING_LIST/>  </PrivateRoute>  } />

                <Route  exact path={ConstantsList.GATEPASS_GATEPASS_LIST}  element={ <PrivateRoute> <GATEPASS_GATEPASS_LIST/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.GATEPASS_GATEPASS_CREATE}  element={ <PrivateRoute> <GATEPASS_GATEPASS_CREATE/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.GATEPASS_GATEPASS_DETAIL_PAGE}  element={ <PrivateRoute> <GATEPASS_GATEPASS_DETAIL_PAGE/>  </PrivateRoute>  } />
                
                <Route  exact path={ConstantsList.USER_MANUAL_BUYER_MANUAL}  element={ <PrivateRoute> <USER_MANUAL_BUYER_MANUAL/>  </PrivateRoute>  } />

                <Route  exact path={ConstantsList.DELEGATION_TASK}  element={ <PrivateRoute> <DELEGATION_TASK/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.DELEGATION_USER}  element={ <PrivateRoute> <DELEGATION_USER/>  </PrivateRoute>  } />

                <Route  exact path={ConstantsList.SINGLESOURCING_SINGLE_SOURCING_CREATION}  element={ <PrivateRoute> <SINGLESOURCING_SINGLE_SOURCING_CREATION/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.SINGLESOURCING_SINGLE_SOURCING_LIST}  element={ <PrivateRoute> <SINGLESOURCING_SINGLE_SOURCING_LIST/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.SINGLESOURCING_SINGLE_SOURCING_DETAILS}  element={ <PrivateRoute> <SINGLESOURCING_SINGLE_SOURCING_DETAILS/>  </PrivateRoute>  } />
                
                <Route  exact path={ConstantsList.ADVANCE_PAYMENT_ADVANCE_PAYMENT_REQUEST_CREATION}  element={ <PrivateRoute> <ADVANCE_PAYMENT_ADVANCE_PAYMENT_REQUEST_CREATION/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.ADVANCE_PAYMENT_ADVANCE_PAYMENT_REQUEST_LIST}  element={ <PrivateRoute> <ADVANCE_PAYMENT_ADVANCE_PAYMENT_REQUEST_LIST/>  </PrivateRoute>  } />
                <Route  exact path={ConstantsList.ADVANCE_PAYMENT_ADVANCE_PAYMENT_REQUEST_DETAILS}  element={ <PrivateRoute> <ADVANCE_PAYMENT_ADVANCE_PAYMENT_REQUEST_DETAILS/>  </PrivateRoute>  } />

                <Route  exact path={ConstantsList.GHG_SCOPE1_CREATION}  element={ <PrivateRoute> <GHG_SCOPE1_CREATION/>  </PrivateRoute>  } />
                {/* supplier_app ENDs here */}

                {/* <Route exact path='/test' element={<Test />}></Route> */}
            </Routes>  
            {/* <Footer/> */}
        </>
    );
}


export default RouterNav;