import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { formatTimeDifference } from "../../../../common/Validations";

const ShowHorizontalStatus = React.forwardRef((props) => {
  const [loadData, setLoadData] = useState(props?.rowdatamodal);

  useEffect(() => {
    setLoadData(props?.rowdatamodal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="dashboard">
      <form className="">
        {loadData && (
          <Row>
            <Col xs={12} md={12} lg={12} className="ShowHorizontalStatus">
              <div className="card">
                <div className="card-body">
                  <div className="activity-container">
                    {/* Line connecting all items */}
                    <div className="activity-line"></div>

                    {loadData?.length > 0 &&
                      loadData.map((item, index) => (
                        <div key={index} className="activity-item text-center">
                          {/* Icons for each action */}
                          <div className="activity-icon">
                            {item.currentAction === "Created" ? (
                              <img
                                src="assets/img/workflow/create.png"
                                alt="workflow"
                                style={{ width: "40px" }}
                                className="mt-1"
                              />
                            ) : // <i className="bi bi-pencil-square fs-4"></i>
                            item.currentAction === "Pending" ? (
                              <img
                                src="assets/img/workflow/pending.png"
                                alt="workflow"
                                style={{ width: "40px" }}
                                className="mt-1"
                              />
                              // <i className="bi bi-hourglass-split fs-4"></i>
                            ) : item.currentAction === "Approve" ? (
                              <img
                                src="assets/img/workflow/approved.png"
                                alt="workflow"
                                style={{ width: "46px" }}
                                className="mt-1"
                              />
                              // <i className="bi bi-check-circle-fill fs-4"></i>
                            ) : item.currentAction === "Submit" ? (
                              <img
                                src="assets/img/workflow/submit.png"
                                alt="workflow"
                                style={{ width: "42px" }}
                                className="mt-1"
                              />
                              // <i className="bi bi-send-fill fs-4"></i>
                            ) : item.currentAction === "Reject" ? (
                              <img
                                src="assets/img/workflow/reject.png"
                                alt="workflow"
                                style={{ width: "46px" }}
                                className="mt-1"
                              />
                              // <i className="bi bi-x-circle-fill fs-4"></i>
                            ) : item.currentAction === "Completed" ? (
                              <img
                                src="assets/img/workflow/complete.png"
                                alt="workflow"
                                style={{ width: "40px" }}
                                className="mt-1"
                              />
                              // <i className="bi bi-flag-fill fs-4"></i>
                            ) : (
                              "-"
                            )}
                          </div>

                          {/* Time */}
                          <span className="text-muted mt-2">
                            {formatTimeDifference(item.updatedAt)}
                          </span>

                          {/* Status label */}
                          <div className="activity-content mt-2">
                            {item.currentAction === "Created" ? (
                              <span className="bg-status bg-new-workflow">
                                {item.currentAction}
                              </span>
                            ) : item.currentAction === "Pending" ? (
                              <span className="bg-status bg-pending-workflow">
                                {item.currentAction}
                              </span>
                            ) : item.currentAction === "Approve" ? (
                              <span className="bg-status bg-success-workflow">
                                {item.currentAction}
                              </span>
                            ) : item.currentAction === "Submit" ? (
                              <span className="bg-status bg-submit-workflow">
                                {item.currentAction}
                              </span>
                            ) : item.currentAction === "Reject" ? (
                              <span className="bg-status bg-danger-workflow">
                                {item.currentAction}
                              </span>
                            ) : item.currentAction === "Completed" ? (
                              <span className="bg-status bg-complete-workflow">
                                {item.currentAction}
                              </span>
                            ) : (
                              "-"
                            )}

                            {item?.approveresInfo?.map((item1, index1) => (
                              <div key={index1} className="mt-2">
                                <div className="fw-bold flex-column text-center">
                                  {/* <p className="mb-0">{item1.userId}</p> */}
                                  <p className="mb-0 text-break">
                                    {item1.firstName}
                                  </p>
                                </div>
                              </div>
                            ))}

                            {item?.approveresInfo ? (
                              " "
                            ) : (
                              <>
                                <div className="mt-2">
                                  <div className="fw-bold flex-column text-center">
                                    {/* <p className="mb-0">{item1.userId}</p> */}
                                    <p className="mb-0 text-break">
                                      &nbsp;
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </form>
    </div>
  );
});

export default ShowHorizontalStatus;
