import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux"; //useDispatch
import { SearchField } from "../../../../Components/formElements/SearchField";
import Constants from "../../../../common/Constants";
// import { getSupplierList } from "../../../../redux/feature/supplier_app/supplierSlice";
import CommonItemLevelDatatableModal from "../CommonItemLevelDatatableModal";
import toast from "react-hot-toast";
import ItemService from "../../../../services/supplier_appCommonService"

const CreateRFQSuppliers = React.forwardRef((props, ref) => {
  const { selectedSuppliers, onSelectedRows, passDataToParent } = props;
  // const dispatch = useDispatch();
  let supplierData = useSelector((state) => state?.supplier);
  let supplierTable = useSelector((state) => state?.supplier.pagination); // User Details from Store
  // console.log("supplierTable",supplierTable)

  const [data, setData] = useState([]);
  const [getCommonSearchTxt, setCommonSearchTxt] = useState("");
  
  useEffect(() => {
    loadInitialSuuplierData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadInitialSuuplierData = () => {
   
    let obj = {
      page: 1,
      per_page: 5,
      searchTerm: "",
      rowFilter: {
        status: {
          $in: ["Registered", "Invited"],
        },
      },
    };

    callSupplierAPI(obj);
 
  };

  const callSupplierAPI = async (obj)=>{

    let supplierSelectedKeys = [
      "AssociateNumber",
      "status",
      "organisationName",
      "sapAssociateNumber",
      "contactfirstName",
      "contactNumber",
      "email",
      "initiator",
      "totalScore",
      "vendorCategory",
      "createdAt",
      "companyCode",
    ];

    await ItemService.getSupplierList(obj).then((item) => {
      if (item?.status === 200) {
        // toast.success(item?.message, {
        //   duration: 3000,
        //   position: "top-right",
        // });
        // Extracting only the selected 20 keys from each object
        const filteredSupplier = item?.data.map((item) => {
          return supplierSelectedKeys.reduce((obj, key) => {
            if (key in item) {
              obj[key] = item[key];
            }
            return obj;
          }, {});
        });
        setData(filteredSupplier);
        // setData(item?.data);
      } else {
        console.log("Supplier Not Found!!");

        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
  }

  const suppliersColumns = [
    {
      name: "Supplier ID",
      selector: (row) => row.AssociateNumber || "--",
      sortable: true,
      cell: (row) => (
        <button
          className="btnInfo"
          // onClick={() => detailedPage(row.AssociateNumber)}
        >
          {row.AssociateNumber || "-"}{" "}
        </button>
      ),
      width: "120px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <>
          {row.status === "Non Invited" ? (
            <span className="bg-table-status bg-table-info-status">
              {row.status}
            </span>
          ) : row.status === "Invited" ? (
            <span className="bg-table-status bg-table-new-status">
              {row.status}
            </span>
          ) : row.status === "In Registration" ? (
            <span className="bg-table-status bg-table-progress-status">
              {row.status}
            </span>
          ) : row.status === "Registered" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.status}
            </span>
          ) : row.status === "Reject" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.status}
            </span>
          ) : (
            row.status
          )}
        </>
      ),
      width: "170px",
    },
    {
      name: "Supplier Name",
      selector: (row) => row.organisationName,
      sortable: true,
      width: "180px",
    },
    {
      name: "SAP Number",
      selector: (row) => row.sapAssociateNumber || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Contact Person",
      selector: (row) => row.contactfirstName || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Contact Number",
      selector: (row) => row.contactNumber || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "EMail",
      selector: (row) => row.email,
      sortable: true,
      width: "250px",
    },
    {
      name: "initiator",
      selector: (row) => row.initiator || "--",
      sortable: true,
      width: "100px",
    },
    {
      name: "ICV Score %",
      selector: (row) => row.totalScore || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Category",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => categoryItemShowModal(row.vendorCategory)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon">
            {" "}
            {row.vendorCategory?.length || 0}{" "}
          </span>
        </>
      ),
      width: "120px",
    },
    {
      name: "Created Date",
      selector: (row) => row.createdAt,
      sortable: true,
      width: "160px",
    },
    {
      name: "Company Code",
      cell: (row) => (
        <>
          <span> {row.companyCode} </span>
        </>
      ),
      width: "150px",
    },
  ];

  const categoryItemColInfo = [
    {
      name: "Category Code",
      cell: (row) => <span>{row.categoryCode} </span>,
      width: "180px",
    },
    {
      name: "Sub Category Code",
      cell: (row) => <span>{row.subCategoryCode} </span>,
      width: "180px",
    },
    {
      name: "Vendor Category",
      cell: (row) => <span>{row.vendorCategoryText} </span>,
      width: "180px",
    },
    {
      name: "Vendor Sub Category",
      cell: (row) => <span>{row.vendorSubCategoryLongText} </span>,
      width: "180px",
    },
  ];

  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");
  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);

  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };
  // OnClick on categoryItem  Data
  const categoryItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(categoryItemColInfo);
    setModalTitle("Category Details");
    setItemLevelModalShow(true);
  };

  const handleSelectedRowsChange = ({ selectedRows }) => {
    onSelectedRows(selectedRows);
  };
  const handleButtonClick = () => {
    passDataToParent(selectedSuppliers);
    props.onHide();
  };

  const [getNewPerPage, setNewPerPage] = useState(10);

  const handlePageChange = (page) => {
    let obj = {
      page: page,
      per_page: getNewPerPage,
      searchTerm: "",
      rowFilter: {
        status: {
          $in: ["Registered", "Invited"],
        },
      },
    };

    callSupplierAPI(obj);

    // dispatch(getSupplierList(obj));
    // setData(supplierData?.data);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setNewPerPage(newPerPage);
    let obj = {
      page: page,
      per_page: newPerPage,
      searchTerm: "",
      rowFilter: {
        status: {
          $in: ["Registered", "Invited"],
        },
      },
    };
    callSupplierAPI(obj);    
    // dispatch(getSupplierList(obj));
    // setData(supplierData?.data);
  };

    // Common Search Function on button
    const handleSearchFuncApi = async () => {
      let obj = {
        rowFilter: {
          status: {
            $in: ["Registered", "Invited"],
          },
        },
        page: 1,
        per_page: 10,
        searchTerm: getCommonSearchTxt,
      };
      callSupplierAPI(obj); 
    };

    
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-90w"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Supplier List
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col
            xs={12}
            md={10}
            lg={10}
            className="d-flex justify-content-center commLeftRightPadding"
          >
            <SearchField
              name="searchKey"
              value={getCommonSearchTxt}
              onChange={(event) => setCommonSearchTxt(event.target.value)}
              onClick={() => handleSearchFuncApi()}
            />
          </Col>
          <Col xs={2} md={2} lg={2}>
            <button onClick={handleButtonClick} className="btnTable">
              Select
            </button>
          </Col>
          <Col xs={12} md={12} lg={12}>
            <DataTable
              title=""
              columns={suppliersColumns}
              data={data}
              progressPending={supplierData?.status}
              paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
              pagination
              paginationServer
              paginationTotalRows={supplierTable.total}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              selectableRows
              onSelectedRowsChange={handleSelectedRowsChange}
              selectableRowsSelected={selectedSuppliers.map(
                (supplier) => supplier.id
              )}
            />
          </Col>
        </Row>

        <CommonItemLevelDatatableModal
          show={itemLevelModalShow}
          onHide={() => itemLevelHideModal()}
          columns={getItemLevelColumns}
          data={getItemLevelData}
          title={getModalTitle}
        />
      </Modal.Body>
    </Modal>
  );
});

export default CreateRFQSuppliers;
