/* eslint-disable no-undef */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ItemService from "../../../services/supplier_appCommonService";

const initialState = {
  rfq: {
    data: [],
    status: true,
    rfxNr: "",
  },
  purchase_requisition: {
    data: [],
    status: true,
    prNumber: "",
    workflowPR: [],
    approvedSelectedRows: [],
    dynamicFormDataCOMM:[],
    dynamicFormDataTECH:[],
    dynamicFormDataStatus:false
  },
  quotation: {
    data: [],
    status: true,
    rfxNr: "",
    quotationListInfo:"",
    pagination:{
      page:"",
      per_page:"",
      total:""
    }
  },
  addRfx: {
    data: [],
    status: true,
  },
  status: true,
  purchaseDocType: "",
  purchaseDocCode: "",
  purchaseDocDescription: "",
  rfqCreation: "",
};

export const sourcingSlice = createSlice({
  name: "sourcing",
  initialState,
  reducers: {
    addPrDetailNumber(state, action) {
      state.purchase_requisition.prNumber = action.payload;
    },
    addPurchaseDocType(state, action) {
      state.purchaseDocType = action.payload.name;
      state.purchaseDocCode = action.payload.code;
      state.purchaseDocDescription = action.payload.description;
    },
    workflowPR(state, action) {
      state.purchase_requisition.workflowPR = action.payload;
    },
    addApprovedSelectedRows(state, action) {
      state.purchase_requisition.approvedSelectedRows = action.payload;
    },
    addDynamicFormDataCOMM(state, action) {
      state.purchase_requisition.dynamicFormDataCOMM = action.payload;
    },
    addDynamicFormDataTECH(state, action) {
      state.purchase_requisition.dynamicFormDataTECH = action.payload;
    },
    saveDynamicForm(state, action) {
      state.purchase_requisition.dynamicFormDataStatus = action.payload;
    },
    addRfqCreation(state, action) {
      state.rfqCreation = action.payload;
    },
    addRfxNr(state, action) {
      state.rfq.rfxNr = action.payload;
    },
    createQuotation(state, action) {
      state.quotation.rfxNr = action.payload;
    },
    quotationListInfo(state, action) {
      state.quotation.quotationListInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRfqQuotation.fulfilled, (state, action) => {
        state.rfq.data = action.payload;
        state.status = true;
      })
      .addCase(getPurchaseRequisition.fulfilled, (state, action) => {
        state.purchase_requisition.data = action.payload;
        state.status = true;
      })
      .addCase(getQuotation.fulfilled, (state, action) => {        
        state.quotation.data = action.payload.data;
        state.quotation.pagination.page = action.payload.page;
        state.quotation.pagination.per_page = action.payload.per_page;
        state.quotation.pagination.total = action.payload.total;
        state.quotation.status = false;
      });
  },
});

export const {
  addPrDetailNumber,
  addPurchaseDocType,
  workflowPR,
  addApprovedSelectedRows,
  addDynamicFormDataCOMM,
  addDynamicFormDataTECH,
  saveDynamicForm,
  addRfqCreation,
  addRfxNr,
  createQuotation,
  quotationListInfo,
} = sourcingSlice.actions;
export default sourcingSlice.reducer;

export const getRfqQuotation = createAsyncThunk("getRfqQuotation", async () => {
  console.log(">> loaded getRfqQuotation");
  const res = await ItemService.getRfqQuotation();
  return res.data;
});

export const getPurchaseRequisition = createAsyncThunk(
  "getPurchaseRequisition",
  async () => {
    console.log(">> loaded getPurchaseRequisition");
    const res = await ItemService.getPurchaseRequisition();
    return res.data;
  }
);

export const getQuotation = createAsyncThunk("getQuotation", async () => {
  console.log(">> loaded getQuotation");
  const res = await ItemService.getQuotation();
  return res;
});

