import config from './configuration'
import ConstantsList from '../common/Constants';

// this block needed to get the login Token and Login user _id 
let localData = localStorage.getItem('userData');
let userData={}
if(localData){
    userData = JSON.parse(localData);
}

const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

/* Service Function starts here */

//POST - getMenuOnUserRole
async function getMenuOnUserRole(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getMenu";
    return callGET(api_url, param);
}

// get info from the configuration.js file
async function getConfig() {
    return config;
}

//POST - Get User Login Details using _id
async function getLoginUserData() { // This method calling from Redux
    let api_url= config.BASE_URL + 'api/getUserDetails';
    let param = {"_id" : userData.data._id}
    return callPOST(api_url, param);
}

// API Error Handling
async function handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
}

// API Error Handling
async function handleError(error) {
    console.log(error.message);
}

// Common GET Method 
async function callGET(api_url) {
    return fetch(api_url,{
                method:'GET',
                headers:{
                    "Content-Type":"application/json",
                    "Accept":"application/json",
                    "Authorization": 'Bearer '+ userData.token
                }
            }).then(response => {
                if (!response.ok) {
                    handleResponseError(response);
                }
                return response.json();
            }).then(items => {
                if(items.responseCode !== 401){
                    // console.log(">> GET Service Call",items);
                    return items;
                }else{
                    console.log(">> Session Timeout",items);
                    localStorage.setItem('token',''); 
                    localStorage.setItem('userData','');
                    window.location = ConstantsList.LOGIN;
                }            
            }).catch(error => {
                console.log(">> e", error);
                handleError(error);
            });
}

// Common POST Method
async function callPOST(api_url,param) {
    // console.log(">> check URL", api_url);
    if(!param){
        param = {}
    }
    param.timeZone = userTimezone;
    return await fetch(api_url,{
                method:'POST',
                headers:{
                    "Content-Type":"application/json",
                    "Accept":"application/json",
                    "Authorization": 'Bearer '+ userData.token
                },
                body:JSON.stringify(param)
            }).then(response => {
                if (!response.ok) {
                    handleResponseError(response);
                }
                return response.json();
            }).then(items => {
                // console.log(">> Service Login Data",items);
                if(items.responseCode !== 401){
                    // console.log(">> POST Service Call",items);
                    return items;
                }else{
                    console.log(">> Session Timeout",items);
                    localStorage.setItem('token',''); 
                    localStorage.setItem('userData','');
                    window.location = ConstantsList.LOGIN;
                }     
               
            }).catch(error => {
                handleError(error);
            });
}

// Common POST Method for Upload
async function callPOSTUpload(api_url,param) {
    // console.log(">>callPOSTUpload param ", param);
    return await fetch(api_url,{
                method:'POST',
                headers:{
                    // 'Content-Type': 'multipart/form-data',
                    "Authorization": 'Bearer '+ userData.token,
                },
                body:param
            }).then(response => {
                if (!response.ok) {
                    handleResponseError(response);
                }
                return response.json();
            }).then(items => {
                // console.log(">> Service Login Data",items);
                if(items.responseCode !== 401){
                    // console.log(">> POST Service Call",items);
                    return items;
                }else{
                    console.log(">> Session Timeout",items);
                    localStorage.setItem('token',''); 
                    localStorage.setItem('userData','');
                    window.location = ConstantsList.LOGIN;
                }     
               
            }).catch(error => {
                handleError(error);
            });
}


//POST - LOGIN USER
async function login(param) {
    let api_url= config.BASE_URL + 'api/login';
    return fetch(api_url,{
                method:'POST',
                headers:{
                    "Content-Type":"application/json",
                    "Accept":"application/json"
                },
                body:JSON.stringify(param)
            }).then(response => {
                if (!response.ok) {
                    handleResponseError(response);
                }
                return response.json();
            }).then(items => {
                if(items.status === 200){
                    console.log(">> Service Login Data",items);
                    localStorage.setItem('token',items.token); 
                    localStorage.setItem('userData',JSON.stringify(items)); 
                    // sessionStorage.setItem('token',items.token); 
                    return items;
                }else{
                    return items;
                }
              
            }).catch(error => {
                handleError(error);
            });
}

//POST - LOGOUT USER
async function logout(param) {
    let api_url= config.BASE_URL + 'api/logout';
    return fetch(api_url,{
        method:'POST',
        headers:{
            "Content-Type":"application/json",
            "Accept":"application/json",
            "Authentication": 'Bearer '+ userData.token
        },
        body:JSON.stringify(param)
    }).then(response => {
        if (!response.ok) {
            handleResponseError(response);
        }
        return response.json();
    }).then(items => {
        console.log(">> Service Logout",items);
        localStorage.setItem('token',''); 
        localStorage.setItem('userData',''); 
        return items;
    }).catch(error => {
        handleError(error);
    });
}

//POST - REGISTER USER
async function registerUser(param) {
    let api_url= config.BASE_URL + 'api/register';
    return callPOST(api_url,param);
}

//POST - USER PROFILE IMAGE UPLOAD 
async function uploadImg(param) {
    let api_url= config.BASE_URL + 'api/uploadImg';
    return callPOSTUpload(api_url,param);
}

//POST - userUpdate Commmon
async function userUpdate(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/userUpdate";
    return callPOST(api_url, param);
}

/* ---------------------------- Common --------------------------------*/

//POST - getMasterData Commmon
async function getMasterData(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getMasterData";
    return callPOST(api_url, param);
}

//POST - getBankMasterData Commmon
async function getBankMasterData(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getBankMaster";
    return callGET(api_url, param);
}

//GET - getConstantVariable Commmon
async function getConstantVariable(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getConstantVariable";
    return callGET(api_url, param);
}

//POST - getApplicationMaster Commmon
async function getApplicationMaster(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getApplicationMaster";
    return callPOST(api_url, param);
}

//POST - getCategory Commmon
async function getCategory(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getCategory";
    return callPOST(api_url, param);
}

//POST - getSubCategory Commmon
async function getSubCategory(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getSubCategory";
    return callPOST(api_url, param);
}

//POST - getSubCategoryRoleId Commmon
async function getSubCategoryRoleId(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getSubCategoryRoleId";
    return callPOST(api_url, param);
}

//POST - addUser Commmon
async function addUser(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/addUser";
    return callPOST(api_url, param);
}

//POST - getUsersListData Commmon
async function getUsersListData(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getUserList";
    return callPOST(api_url, param);
}

/* ---------------------------- Workflow --------------------------------*/

//POST - getWorkflowInboxCards
async function getWorkflowInboxCards(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/workflowInboxCards";
    return callPOST(api_url, param);
}

//POST - getworkflowInboxList
async function getworkflowInboxList(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/workflowInboxList";
    return callPOST(api_url, param);
}

//POST - workflowApproveReject
async function workflowApproveReject(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/actionOnWorkflowEvent";
    return callPOST(api_url, param);
}

//workflowActionAPI
async function workflowActionAPI(URL, param) { // This method calling from Redux
    let api_url= config.BASE_URL + URL;
    return callPOST(api_url, param);
}

/* ---------------------------- Sourcing --------------------------------*/

//POST - getRfqQuotation
async function getRfqQuotation(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getRequestForQuotation";
    return callPOST(api_url, param);
}
//POST - getPurchaseRequisition
async function getPurchaseRequisition(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getPurchaseRequisition";
    return callPOST(api_url, param);
}
//post addRfx
async function addRfx(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/addRfx";
    return callPOST(api_url, param);
}
//post addPR
async function addPR(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/addPR";
    return callPOST(api_url, param);
}
//POST - getPRDetail
async function getPRDetail(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getPRDetail";
    return callPOST(api_url, param);
}
//POST - getRfxDocumentDetail
async function getRfxDocumentDetail(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getRfxDocumentDetail";
    return callPOST(api_url, param);
}
//POST - getPRLineItems
async function getPRLineItems(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getPRLineItems";
    return callPOST(api_url, param);
}
//POST - rfxAcknowledgementOrRegret
async function rfxAcknowledgementOrRegret(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/rfxAcknowledgementOrRegret";
    return callPOST(api_url, param);
}
//POST - addQuotation
async function addQuotation(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/addQuotation";
    return callPOST(api_url, param);
}
//POST - getQuotation
async function getQuotation(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getQuotation";
    return callPOST(api_url, param);
}
//POST - getQuotationDatail
async function getQuotationDatail(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getQuotationDatail";
    return callPOST(api_url, param);
}


/* ---------------------------- Supplier --------------------------------*/

//POST - getSupplierList
async function getSupplierList(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getSupplierList";
    return callPOST(api_url, param);
}
//POST - getSupplierCategorySelection
async function getSupplierCategorySelection(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getSupplierCategorySelection";
    return callPOST(api_url, param);
}
//POST - addSupplier
async function addSupplier(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/addSupplier";
    return callPOST(api_url, param);
}
//POST - getUserDetail
async function getUserDetail(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getUserDetail";
    return callPOST(api_url, param);
}
//POST - getSupplierDatail
async function getSupplierDatail(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getSupplierDatail";
    return callPOST(api_url, param);
}
//POST - getSupplierBank
async function getSupplierBank(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getSupplierBank";
    return callPOST(api_url, param);
}


// ------ SELF SUPPLIER REG API WITHOUT AUTH------- 
async function addSelfSupplier(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/addSelfSupplier";
    return callPOST(api_url, param);
}
async function getMasterDataSelf(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getMasterDataSelf";
    return callPOST(api_url, param);
}



/* ---------------------------- PO COLLABRATION --------------------------------*/
//POST - getPurchaseOrder
async function getPurchaseOrder(param) { // This method calling from Redux
    // let api_url= config.BASE_URL + "api/getPurchaseOrder";
    let api_url= config.BASE_URL + "api/generateQuery";
    return callPOST(api_url, param);
}
//POST - addPO
async function addPO(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/addPO";
    return callPOST(api_url, param);
}
//POST - getPurchaseOrderDetail
async function getPurchaseOrderDetail(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getPurchaseOrderDetail";
    return callPOST(api_url, param);
}


/* -------------- ASN --------------*/
//POST - getASN
async function getASN(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getAsn";
    return callPOST(api_url, param);
}

//POST - addASN
async function addASN(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/addASN";
    return callPOST(api_url, param);
}
//POST - getAsnDetail
async function getAsnDetail(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getAsnDetail";
    return callPOST(api_url, param);
}
//POST - getPOAsn
async function getPOAsn(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getPOAsn";
    return callPOST(api_url, param);
}

/* -------------- GRN --------------*/
//POST - addGRN
async function addGRN(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/addGRN";
    return callPOST(api_url, param);
}

//POST - getGrnDatail
async function getGrnDatail(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getGrnDatail";
    return callPOST(api_url, param);
}

//POST - AcknowledgePO
async function AcknowledgePO(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/acknowledgePO";
    return callPOST(api_url, param);
}
//POST - getPOGrn
async function getPOGrn(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getPOGrn";
    return callPOST(api_url, param);
}


/* -------------- PO Invoice --------------*/
//POST - getPOInvoice
async function getPOInvoice(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getPOInvoice";
    return callPOST(api_url, param);
}
//POST - createInvoice
async function createInvoice(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/createInvoice";
    return callPOST(api_url, param);
}
//POST - addInvoice
async function addInvoice(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/addInvoice";
    return callPOST(api_url, param);
}
//POST - getInvoiceDatail
async function getInvoiceDatail(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getInvoiceDatail";
    return callPOST(api_url, param);
}
//POST - getInvoiceList
async function getInvoiceList(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getInvoiceList";
    return callPOST(api_url, param);
}
//POST - uploadOCRFile
async function uploadOCRFile(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/uploadOCRFileV1";
    return callPOSTUpload(api_url, param);
}


/* -------------- Service Entrysheet --------------*/
//POST - addEntrysheet
async function addEntrysheet(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/addEntrysheet";
    return callPOST(api_url, param);
}
//POST - getSesDatail
async function getSesDatail(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getSesDatail";
    return callPOST(api_url, param);
}
//POST - getSesList
async function getSesList(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getSesList";
    return callPOST(api_url, param);
}
//POST - getPOSes
async function getPOSes(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getPOSes";
    return callPOST(api_url, param);
}
//POST - createSES
async function createSES(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/createSES";
    return callPOST(api_url, param);
}


//POST - cancelDocument
async function cancelDocument(param) { // This method calling from Redux
    let api_url= config.BASE_URL +"api/cancelDocument";
    return callPOST(api_url, param);
}
//POST - getGoodsReceivedNote
async function getGoodsReceivedNote(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getGoodsReceivedNote";
    return callPOST(api_url, param);
}

/* ---------------------------- Gatepass --------------------------------*/
//POST - getGatepassList
async function getGatepassList(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getGatepassList";
    return callPOST(api_url, param);
}

//POST - getGatepass Create
async function getGatepassCreate(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/addGatepass";
    return callPOST(api_url, param);
}

//POST - getAsnNumForGatepass Create
async function getAsnNumForGatepass(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getAsnNumForGatepass";
    return callPOST(api_url, param);
}

/* ---------------------------- SingleSourcing --------------------------------*/

//POST - addSingleSourceRequest Commmon
async function addSingleSourceRequest(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/addSingleSourceRequest";
    return callPOST(api_url, param);
}

//POST - getSingleSourcingList Commmon
async function getSingleSourcingList(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getSingleSourceRequest";
    return callPOST(api_url, param);
}


const func = {
    getMasterData,
    getBankMasterData,    
    getConstantVariable,
    getApplicationMaster,
    getCategory,
    getSubCategory,
    getSubCategoryRoleId,
    addUser,
    getUsersListData,

    login,
    logout,
    registerUser,
    userUpdate,
    uploadImg,
    handleResponseError,
    handleError,
    callGET,
    callPOST,
    callPOSTUpload,
    
    getConfig,
    getMenuOnUserRole,
    getLoginUserData,
    
    getWorkflowInboxCards,
    getworkflowInboxList,
    workflowApproveReject,
    workflowActionAPI,

    getRfqQuotation,
    getPurchaseRequisition,
    getQuotation,
    getQuotationDatail,
    addRfx,
    addPR,
    getPRDetail,
    getRfxDocumentDetail,
    getPRLineItems,
    rfxAcknowledgementOrRegret,
    addQuotation,

    addSelfSupplier,
    getMasterDataSelf,

    getSupplierList,
    getSupplierCategorySelection,
    addSupplier,
    getUserDetail,
    getSupplierDatail,
    getSupplierBank,

    getPurchaseOrder,
    addPO,
    getGoodsReceivedNote,

    getASN,
    addASN,
    getAsnDetail,
    getPOAsn,

    addGRN,
    getGrnDatail,
    AcknowledgePO,        
    getPOGrn,
    
    getPOInvoice,
    createInvoice,
    addInvoice,
    getInvoiceDatail,
    getInvoiceList,
    uploadOCRFile,

    addEntrysheet,
    getSesDatail,
    getSesList,
    getPOSes,
    createSES,

    cancelDocument,
    getPurchaseOrderDetail,

    getGatepassList,
    getGatepassCreate,
    getAsnNumForGatepass,

    addSingleSourceRequest,
    getSingleSourcingList,
};

export default func;